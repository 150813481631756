import React from "react";
import axios from "axios";
import authHeader from "../../services/Auth.header";
import envconfig from "../../environment-config";
import { useNavigate } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";
import Spinner from "react-bootstrap/esm/Spinner";

function UsersManagement() {
  const navigation = useNavigate();
  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState(false);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  const [message, setMessage]: [string, (error: string) => void] =
    React.useState("");
  const [selectedFile, setSelectedFile] = React.useState<File>();
  const [errors, setErrors]: [[], (errors: []) => void] =
    React.useState([]);
  const [numberOfRecords, setNumberOfRecords]: [number, (numberOfRecords: number) => void] =
    React.useState(0);
  const [numberOfFailedRecords, setNumberOfFailedRecords]: [number, (numberOfFailedRecords: number) => void] =
    React.useState(0);

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmission = () => {
    const formData: any = new FormData();
    formData.append("csv", selectedFile);
    setLoading(true);

    return axios
      .post(`${envconfig.API_BASE_URL}/users/import`, formData, {
        headers: authHeader(),
      })
      .then((response) => {
        setError("");
        setMessage("Users imported successfully.");
        setLoading(false);
      })
      .catch((ex) => {
        if (ex.response.status === 401) {
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        if (ex.response.data.errors) {
          setNumberOfRecords(ex.response.data.total);
          setNumberOfFailedRecords(ex.response.data.successfullCount);
          setErrors(ex.response.data.errors);
        } else {
          const error =
            ex.response.status === 404
              ? "Resource Not found"
              : "An unexpected error has occurred";
          setError(error);
        }
        setMessage("");
        setLoading(false);
      });
  };

  return (
    <div style={{ margin: "10px" }}>
      <div
        style={{
          width: "100%",
          padding: "20px",
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div>
          <h2 style={{ color: "#06409e", fontSize: "1.2rem" }}>User import</h2>
          <div>
            <div>Imports users from csv.</div>
            <div>Format: first name, last name,	email, phone number, unique ID.</div>
            <div style={{ marginTop: "20px" }}>
              <input type="file" name="file" onChange={changeHandler} />
            </div>
          </div>
        </div>
        <div style={{ marginTop: "30px" }}>
          <button
            style={{ marginLeft: "auto" }}
            className="primary-button"
            onClick={handleSubmission}
          >
            <span>
              {loading && (
                <Spinner animation="border" variant="light"></Spinner>
              )}
              Import
            </span>{" "}
          </button>
        </div>
        {error && <p>{error}</p>}
        {errors.length > 0 &&
          <div>
            <span>Number of failed records: {numberOfFailedRecords}/{numberOfRecords}</span>
            <ul style={{ color: "red", maxHeight: "200px", overflow: "scroll" }}>
              {errors.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
            {message}
          </div>
        }
        {message && <p>{message}</p>}
      </div>
    </div>
  );
}

export default UsersManagement;
