import { IUser } from "../interfaces/IUser";
import { IContract } from "../interfaces/IContract";

class LocalStorageService {
  AddNftToMint(contract: IContract) {
    var nftsList: IContract[];
    var listJson = localStorage.getItem("NFTsToMint");

    if (listJson === null || listJson === "") {
      nftsList = [];
    } else {
      nftsList = JSON.parse(listJson);
    }

    if (!nftsList.some(item => item.id === contract.id)) {
      nftsList.push(contract);
    }

    localStorage.setItem("NFTsToMint", JSON.stringify(nftsList));
  }

  getNftsToMint(): IContract[] {
    var listJson = localStorage.getItem("NFTsToMint");
    if (listJson === null || listJson === "") {
      return [];
    }

    return JSON.parse(listJson);
  }

  clearNftsToMint() {
    localStorage.removeItem("NFTsToMint");
  }

  setUser(user: IUser) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  clearCache() {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiresAt");
  }

  getCurrentUser(): IUser | undefined {
    const userStr = localStorage.getItem("user");
    let user: IUser | undefined = undefined;
    if (userStr) user = JSON.parse(userStr);

    return user;
  }
}

export default new LocalStorageService();
