import { SvgIcon } from "@mui/material";
import React from "react";
import "reactjs-popup/dist/index.css";
import userIcon from "../../assets/user.png";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../services/User.service";
import { useNavigate, useLocation } from "react-router-dom";
import LocalStorageService from "../../services/LocalStorage.service";
import { IUser } from "../../interfaces/IUser";

function Profile() {
  const navigate = useNavigate();
  const [showResetPin, setShowResetPin] = React.useState(false);
  const [openedAdvanced, setOpenedAdvanced] = React.useState(false);
  const [showPrivateKey, setShowPrivateKey] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [successful, setSuccessful] = React.useState(false);
  const [privateKey, setPrivateKey] = React.useState("");
  const [copySuccess, setCopySuccess] = React.useState(false);
  const [mintMessage, setMintMessage] = React.useState("");
  const [mintSuccess, setMintSuccess] = React.useState(false);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const [returnUrl, setReturnUrl] = React.useState(query.get('url'));


  const handleLogout = () => {
    UserService.logout();
    navigate("/");
  };

  const openAdvanced = () => {
    setOpenedAdvanced(true);
  };

  const backToProfile = () => {
    setOpenedAdvanced(false);
    setShowResetPin(false);
    setShowPrivateKey(false);
  };

  const resetPin = () => {
    setShowResetPin(true);
    setSuccessful(false);
  };

  const handleShowPrivateKey = () => {
    setShowPrivateKey(true);
    setOpenedAdvanced(false);
    setSuccessful(false);
  };

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(privateKey).then(
      () => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      },
      () => {
        console.log("Failed to copy text to clipboard.");
      }
    );
  };

  const validationSchema = () => {
    return Yup.object().shape({
      currentPin: Yup.string().required("This field is required!"),
      newPin: Yup.string().required("This field is required!"),
    });
  };

  const handleReset = (formValue: { currentPin: string; newPin: string }) => {
    const { currentPin, newPin } = formValue;
    setMessage("");
    setLoading(true);
    setSuccessful(false);

    UserService.resetPin(user?.email ?? "", currentPin, newPin).then(
      (response) => {
        setShowResetPin(false);
        setSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setLoading(false);
        setSuccessful(false);
      }
    );
  };

  const handleMintYourNFTs = () => {
    setMintMessage("");
    setMintSuccess(false);

    if (!user) {
      throw "User not authenticated.";
    }

    return UserService.mintAllNfts(user)
      .then((response) => {
        setMintMessage(`Minted ${response.data.minted} badges.`);
        setMintSuccess(true);
      })
      .catch((error) => {
        setMintMessage("");
        setMintSuccess(false);
      });
  };

  let user: IUser | undefined = LocalStorageService.getCurrentUser();

  const initialValues = {
    currentPin: "",
    newPin: "",
  };

  const initialValuesPrivateKeyLogin = {
    pin: "",
  };

  const getPrivateKey = (formValue: { pin: string }) => {
    const { pin } = formValue;
    setMessage("");
    setLoading(true);
    setSuccessful(false);

    UserService.getPrivateKey(user?.email ?? "", pin).then(
      (data) => {
        setSuccessful(true);
        setPrivateKey(data.privateKey);
        setOpenedAdvanced(false);
      },
      (error) => {
      }
    );
  };

  return (
    <div>
      {showResetPin && (
        <Box>
          <div
            onClick={backToProfile}
            style={{
              textAlign: "left",
              color: "#06409e",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <SvgIcon
              style={{ color: "#06409e", fontSize: "xxx-large" }}
              component={NavigateBeforeIcon}
            />
            <span style={{ color: "#06409e" }}>Back</span>
          </div>

          <div
            style={{
              textAlign: "center",
              maxWidth: "400px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <label className="pdi-login-label" style={{marginTop:"50px"}}>Reset your pin</label>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleReset}
            >
              <Form>
                {!successful && (
                  <div>
                    <div className="form-group">
                      <Field
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "white !important",
                        }}
                        name="currentPin"
                        type="password"
                        placeholder="Current PIN"
                        className="form-control input-field "
                        inputMode="numeric"
                      />
                      <ErrorMessage
                        name="currentPin"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        style={{
                          marginBottom: "10px",
                          backgroundColor: "white !important",
                        }}
                        name="newPin"
                        type="password"
                        placeholder="New PIN"
                        className="form-control input-field "
                        inputMode="numeric"
                      />
                      <ErrorMessage
                        name="newPin"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>
                    <div className="form-group" style={{ marginTop: "20px" }}>
                      <button type="submit" className="full-width-button">
                        <span>Reset</span>
                      </button>
                    </div>
                  </div>
                )}
                {message && (
                  <div className="form-group">
                    <div
                      className={
                        successful
                          ? "alert alert-success"
                          : "alert alert-danger"
                      }
                      role="alert"
                    >
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </div>
        </Box>
      )}

      {showPrivateKey && (
        <Box>
          <div
            onClick={backToProfile}
            style={{
              textAlign: "left",
              color: "#06409e",
              position: "absolute",
              left: 0,
              top: 0,
              marginBottom: "20px",
            }}
          >
            <SvgIcon
              style={{ color: "#06409e", fontSize: "xxx-large" }}
              component={NavigateBeforeIcon}
            />
            <span style={{ color: "#06409e" }}>Back</span>
          </div>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginTop: "30px", textAlign: "center" }}
          >
            <span className="page-title">Wallet Secret</span>
          </Typography>

          <Typography
            style={{ textAlign: "center" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <p
              style={{
                marginBottom: 40,
                textAlign: "center",
              }}
            >
              Enter your pin to access your Private Key.
            </p>

            <p style={{ fontWeight: "bold", fontSize: "0.8em" }}>
              Enter your PIN
            </p>

            <Formik
              initialValues={initialValuesPrivateKeyLogin}
              // validationSchema={this.validationSchema}
              onSubmit={getPrivateKey}
            >
              <Form>
                <div className="form-group">
                  <Field
                    id="pin"
                    style={{
                      marginBottom: "10px",
                      backgroundColor: "white !important",
                    }}
                    name="pin"
                    type="password"
                    className="form-control input-field"
                    placeholder="PIN"
                    inputMode="numeric"
                  />
                  <ErrorMessage
                    name="pin"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <button
                    style={{
                      marginTop: "40px",
                    }}
                    type="submit"
                    className="full-width-button"
                    disabled={loading}
                  >
                    <span>Show</span>
                  </button>
                </div>
                {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )}
              </Form>
            </Formik>
          </Typography>
        </Box>
      )}

      {openedAdvanced && (
        <Box>
          <div
            onClick={backToProfile}
            style={{
              textAlign: "left",
              color: "#06409e",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <SvgIcon
              style={{ color: "#06409e", fontSize: "xxx-large" }}
              component={NavigateBeforeIcon}
            />
            <span style={{ color: "#06409e" }}>Back</span>
          </div>

          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "left", marginTop: "30px" }}
          >
            <span className="page-title">Advanced</span>
          </Typography>
          <Typography
            style={{ textAlign: "left" }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <p
              style={{
                marginBottom: 40,
                textAlign: "left",
              }}
            >
              Your badge is saved in on the Avalanche Network(AVAX). You can
              always use an AVAX compatible wallet to view your badges.
            </p>

            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <button
                className="full-width-button"
                style={{ marginBottom: 10, maxWidth: "500 px" }}
                onClick={handleMintYourNFTs}
              >
                <span>Mint your badges</span>
              </button>
              {mintMessage && (
                <div className="form-group">
                  <div
                    className={
                      mintSuccess
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {mintMessage}
                  </div>
                </div>
              )}
            </div>

            <h3 className="title-codes" style={{ marginTop: "40px" }}>ACCOUNT CODES</h3>
            <div className="horizontal-separator"></div>
            <div className="titled-wallet-address">Wallet Address:</div>
            <div className="font-size-0-8 pb20">{user?.wallet}</div>

            <div style={{ maxWidth: "400px", margin: "auto" }}>
              <button
                className="full-width-button"
                style={{ marginBottom: 10, maxWidth: "500 px" }}
                onClick={handleShowPrivateKey}
              >
                <span>Show Private Key</span>
              </button>
            </div>
          </Typography>
        </Box>
      )}

      {showPrivateKey && privateKey && (
        <Box>
          <div
            onClick={backToProfile}
            style={{
              textAlign: "left",
              color: "#06409e",
              position: "absolute",
              left: 0,
              top: 0,
            }}
          >
            <SvgIcon
              style={{ color: "#06409e", fontSize: "xxx-large" }}
              component={NavigateBeforeIcon}
            />
            <span style={{ color: "#06409e" }}>Back</span>
          </div>
          <div
            style={{
              backgroundColor: "#E60D2E",
              color: "white",
              height: "auto",
              padding: "1em",
              borderRadius: "6px",
              marginTop: "1em",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center", marginBottom: "1em" }}
            >
              <span className="page-title" style={{ color: "white" }}>
                Wallet Secret
              </span>
            </Typography>
            <div
              style={{
                textAlign: "center",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              id="modal-modal-description"
            >
              <div
                style={{
                  overflowWrap: "break-word",
                  marginBottom: "2em",
                  marginLeft: "2em",
                  marginRight: "2em",
                }}
              >
                DO NOT share this secret with anyone! This secret can be used to
                steal all your badges.
              </div>

              <div
                style={{
                  backgroundColor: "white",
                  color: "white",
                  padding: "2em",
                  borderRadius: "6px",
                }}
              >
                <div
                  style={{
                    color: "#06409E",
                    fontWeight: "bold",
                    fontSize: "0.9em",
                  }}
                >
                  SECRET CODE:
                </div>
                <div
                  style={{
                    overflowWrap: "break-word",
                    color: "grey",
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                >
                  {privateKey}
                </div>
                <button
                  className="full-width-button"
                  style={{
                    backgroundColor: "#E5EFFF",
                    border: "none",
                    color: "#0f65f0",
                  }}
                  onClick={copyCodeToClipboard}
                >
                  {copySuccess ? <span>Copied!</span> : <span>Copy code</span>}
                </button>
              </div>
            </div>
          </div>
        </Box>
      )}

      {!openedAdvanced && !showResetPin && !showPrivateKey && (
        <Box>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ textAlign: "left", marginTop: "30px" }}
          >
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              maxWidth: "400px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            id="modal-modal-description"
            sx={{ mt: 2 }}
          >
            <img
              className="thumb-image center"
              src={userIcon}
              alt="User icon."
            />
            <div
              style={{
                color: "#06409e",
                fontWeight: "bold",
                marginBottom: 40,
              }}
            >
              {user?.email}
            </div>

            <div>
              <button
                className="full-width-button"
                style={{ marginBottom: 10 }}
                onClick={resetPin}
              >
                <span>Reset PIN</span>
              </button>
            </div>

            <div>
              <button
                className="full-width-button"
                style={{ marginBottom: 10 }}
                onClick={handleLogout}
              >
                <span>Log out</span>
              </button>
            </div>
{/* 
            <div>
              <button
                className="full-width-button"
                style={{
                  backgroundColor: "#fff",
                  border: "1px solid #0f65f0",
                  color: "#0f65f0",
                }}
                onClick={openAdvanced}
              >
                <span>Advanced</span>
              </button>
            </div> */}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export default Profile;
