import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, SvgIcon } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";

interface Properties {
    image: Buffer | undefined;
    name: string | undefined;
    description: string | undefined;
    eventName: string | undefined;
    points: number | undefined;
    setOpen: (newState: boolean) => void;
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "55.5%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    textAlign: "center",
    maxWidth: "400px",
    width: "95vw",
};

function NFTdetail({ image, name, description, eventName, points, setOpen }: Properties) {
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Modal
                style={{ width: "90vw", maxWidth: "90vw" }}
                open={true}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <SvgIcon
                        style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            color: "grey",
                        }}
                        className="profile-icon"
                        fontSize="large"
                        component={CloseIcon}
                        onClick={() => setOpen(false)} />

                    <img
                        className="nft-image-detail"
                        src={`data:image/jpeg;base64, ${image?.toString("base64")}`}
                        alt="Cool badge." />

                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <div className="center nft-detail-title">
                            {name}
                        </div>
                        <div style={{ backgroundColor: "lightgray", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", width: "fit-content", marginLeft: "auto", marginRight: "auto", marginTop: "10px", marginBottom: "10px" }}>
                            {eventName}
                        </div>
                    </Typography>
                    <Typography id="modal-modal-description">
                        <div style={{ fontSize: "0.9rem" }}>
                            {description}
                        </div>
                        <div style={{ backgroundColor: "#F5B815", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", width: "fit-content", marginLeft: "auto", marginRight: "auto", marginTop: "10px", marginBottom: "10px" }}>
                            {points} Points
                        </div>
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

export default NFTdetail;