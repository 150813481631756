import NftsImport from "../imports/NftsImport";
import UsersManagement from "../imports/UsersManagement";

function Imports() {
    return (
        <div>
            <div style={{padding:"10px"}}>
                <UsersManagement/>
                <NftsImport />
            </div>
        </div>
    );
}

export default Imports;
