import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../services/User.service";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import hillsAndMedalsIcon from "../../assets/hillsAndMedals.png";
import localStorageService from "../../services/LocalStorage.service";
import React from "react";

export default function Login() {
  const navigation = useNavigate();
  const { id } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const returnUrl = query.get('url');

  let externalUserId = id;

  const [loading, setLoading] = React.useState(false);
  const [loadingWallet, setLoadingWallet] = React.useState(false);
  const [message, setMessage] = React.useState("");

  function validationSchema() {
    return Yup.object().shape({
      email: Yup.string().required("This field is required!"),
      pin: Yup.string().required("This field is required!"),
    });
  }

  function handleLogin(formValue: { email: string; pin: string }) {
    const { email, pin } = formValue;
    setMessage("");
    setLoading(true);

    var nftsToMint = localStorageService.getNftsToMint();

    UserService.login(email, pin, nftsToMint).then(
      (data) => {
        if (returnUrl) {
          navigation(returnUrl);
        }
        else {
          navigation("/wallet");
        }
      },
      (error) => {
        console.log(error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response.status === 404) {
          setLoading(false);
          setMessage(resMessage);
        } else {
          setLoading(false);
          setMessage(resMessage);
        }
      }
    );
  }

  function handleRegister() {
    navigation("/register");
  }

  if (externalUserId) {
    var nftsToMint = localStorageService.getNftsToMint();
    UserService.loginWithExternalId(
      externalUserId,
      nftsToMint
    ).then(
      (data) => {
        if (returnUrl) {
          navigation(returnUrl);
        }
        else {
          navigation("/wallet");
        }
      },
      (error) => {
        console.log(error);
        setLoadingWallet(false);
        setMessage("");
        externalUserId = "";
      }
    );
  }

  const initialValues = {
    email: "",
    pin: "",
  };

  return (
    <div>
      {!externalUserId && (
        <div className="col-md-12">
          <div className="our-card card-container">
            <div className="pdi-login-header-container" style={{ marginTop: "20px" }}>
              <p className="page-action">Welcome to AMPLIFY Rewards</p>
            </div>

            <div className="pdi-form-body-container" style={{ zIndex: 5 }}>
              <label className="pdi-login-label">Login to your Rewards</label>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleLogin}
              >
                <Form>
                  <div className="form-group">
                    <Field
                      id="email"
                      style={{
                        marginBottom: "10px",
                        backgroundColor: "white !important",
                      }}
                      name="email"
                      type="text"
                      className="form-control input-field"
                      placeholder="E-mail"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group">
                    <Field
                      id="pin"
                      style={{
                        marginBottom: "10px",
                        backgroundColor: "white !important",
                      }}
                      name="pin"
                      type="password"
                      className="form-control input-field"
                      placeholder="PIN"
                      inputMode="numeric"
                    />
                    <ErrorMessage
                      name="pin"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div
                    style={{
                      color: "#0F65F0",
                      float: "right",
                      fontSize: "0.88rem",
                      paddingRight: "20px",
                    }}
                  >
                    <a href="/forgotten-pin/" style={{ textDecoration: "none" }}>Forgotten PIN</a>
                  </div>
                  <div className="form-group">
                    <button
                      style={{
                        marginTop: "40px",
                      }}
                      type="submit"
                      className="full-width-button"
                      disabled={loading}
                    >
                      <span>
                        {loading && (
                          <Spinner
                            animation="border"
                            variant="light"
                          ></Spinner>
                        )}
                        Login
                      </span>
                    </button>

                    <div className="horizontal-separator">
                      <span style={{ color: "#0F65F0" }}>or</span>
                    </div>

                    <button
                      style={{
                        backgroundColor: "#D8E2F0",
                        color: "#0f65f0",
                      }}
                      type="button"
                      className="full-width-button"
                      disabled={loading}
                      onClick={handleRegister}
                    >
                      <span>Create Account</span>
                    </button>
                  </div>
                  {message && (
                    <div className="form-group">
                      <div className="alert alert-danger" role="alert">
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          </div>

          <div className="background-img-wrapper">
            <img
              className="hills-background-image"
              src={hillsAndMedalsIcon}
              alt="NFTs."
            />
          </div>
        </div>
      )}
      {externalUserId && (
        <div className="col-md-12">
          {/* {user && <Navigate to="/wallet" replace={true} />} */}
          <div className="our-card card-container">
            <div className="pdi-login-header-container">
              <p className="page-action">Amplify Rewards</p>
            </div>

            <div className="pdi-form-body-container" style={{ zIndex: 5 }}>
              <div>
                <label className="pdi-login-label">Loading your rewards</label>
              </div>
              {loadingWallet && (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="large-spinner"
                ></Spinner>
              )}

              {message && (
                <div className="form-group">
                  <div className="alert alert-danger" role="alert">
                    {message}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="background-img-wrapper">
            <img
              className="hills-background-image"
              src={hillsAndMedalsIcon}
              alt="NFTs."
            />
          </div>
        </div>
      )}
    </div>
  );
}