import { IContract } from "../../interfaces/IContract";
import React from "react";
import axios from "axios";
import authHeader from "../../services/Auth.header";
import { Modal, SvgIcon } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import envconfig from "../../environment-config";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Button from "@mui/material/Button";
import * as Yup from "yup";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import logo from "../../assets/picture.png";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";
import { NFTcontract } from "./NFTcontract";
import { IEvent } from "../../interfaces/IEvent";
import { ICategory } from "../../interfaces/ICategory";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  minWidth: "200px",
  maxWidth: "400px",
  width: "100%;",
};

function NFTcontractList() {
  const navigation = useNavigate();

  // const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
  const defaultPosts: IContract[] = [];
  const defaultEvents: IEvent[] = [];
  const [selectedEvent, setSelectedEvent] = React.useState<string>("");
  const [selectedCategory, setSelectedCategory] = React.useState<string>("");
  const [posts, setPosts]: [IContract[], (posts: IContract[]) => void] =
    React.useState(defaultPosts);
  const [error, setError]: [string, (error: string) => void] =
    React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [nftFile, setNftFile] = React.useState<File>();
  const [nftFileUrl, setNftFileUrl] = React.useState<string>(logo);
  const [showSpinner, setShowSpinner] = React.useState<boolean>(false);
  const [showContractsSpinner, setShowContractsSpinner] =
    React.useState<boolean>(false);
  const [events, setEvents]: [IEvent[], (events: IEvent[]) => void] =
    React.useState(defaultEvents);
  const defaultCategories: ICategory[] = [];
  const [categories, setCategories]: [ICategory[], (categories: ICategory[]) => void] =
    React.useState(defaultCategories);

  const initialValues = {
    name: "",
    symbol: "",
    description: "",
    selectedEvent: "",
    points: 0,
  };

  const validationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required("This field is required!")
        .matches(/^[a-zA-Z0-9=?!#$%';()*+,\-./:@\\[\]^_]*$/, `Use only letters, numbers and following characters =?!#$%';()*+,-./:@[\\]^_`),
      symbol: Yup.string().required("This field is required!")
        .matches(/^[a-zA-Z0-9-]+$/, 'Use only letters, numbers and hyphens.')
        .max(10, 'Symbol must be at most 10 characters.'),
      description: Yup.string().required("This field is required!"),
      points: Yup.number().required("This field is required!"),
    });
  };

  const handleSubmit = (formValue: {
    name: string;
    symbol: string;
    description: string;
    points: number;
  }) => {
    const { name, symbol, description, points } = formValue;

    const formData: any = new FormData();
    formData.append("name", name);
    formData.append("symbol", symbol);
    formData.append("description", description);
    formData.append("image", nftFile);
    formData.append("points", points);
    formData.append("price", 0);
    formData.append("selectedEvent", selectedEvent);
    formData.append("selectedCategory", selectedCategory);

    setShowSpinner(true);

    return axios
      .post(`${envconfig.API_BASE_URL}/contracts/deploy`, formData, {
        headers: authHeader(),
      })
      .then((response) => {
        loadContracts();
        setOpen(false);
        setNftFile(undefined);
        setNftFileUrl("");
        setShowSpinner(false);
      })
      .catch((ex) => {
        if (ex.response.status === 401) {
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        const error =
          ex.response.status === 404
            ? "Resource Not found"
            : "An unexpected error has occurred";
        setError(error);
        setShowSpinner(false);
      });
  };

  const loadContracts = () => {
    setShowContractsSpinner(true);

    axios
      .get<IContract[]>(`${envconfig.API_BASE_URL}/contracts`, {
        headers: authHeader(),
      })
      .then((response) => {
        setPosts(response.data);
        setError("");
        setShowContractsSpinner(false);
      })
      .catch((ex) => {
        setShowContractsSpinner(false);

        if (ex.response.status === 401) {
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        const error =
          ex.response.status === 404
            ? "Resource Not found"
            : "An unexpected error has occurred";
        setError(error);
        // setLoading(false);
      });
  };

  const loadEvents = () => {
    axios
      .get<IEvent[]>(`${envconfig.API_BASE_URL}/events`, {
        headers: authHeader(),
      })
      .then((response) => {
        setEvents(response.data);
        setError("");
      })
      .catch((ex) => {
        if (ex.response.status === 401) {
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        const error =
          ex.response.status === 404
            ? "Resource Not found"
            : "An unexpected error has occurred";
        setError(error);
        // setLoading(false);
      });
  };

  const loadCategories = () => {
    axios
      .get<ICategory[]>(`${envconfig.API_BASE_URL}/categories`, {
        headers: authHeader(),
      })
      .then((response) => {
        setCategories(response.data);
        setError("");
      })
      .catch((ex) => {
        if (ex.response.status === 401) {
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        const error =
          ex.response.status === 404
            ? "Resource Not found"
            : "An unexpected error has occurred";
        setError(error);
        // setLoading(false);
      });
  };

  const handleEventChange = (x: any) => {
    setSelectedEvent(x.target.value);
  }

  const handleCategoryChange = (x: any) => {
    setSelectedCategory(x.target.value);
  }

  React.useEffect(() => {
    loadContracts();
    loadEvents();
    loadCategories();
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <div className="add-contract-button-container">
        <SvgIcon
          className="profile-icon-tight"
          fontSize="large"
          component={AddCircleOutlineIcon}
          onClick={handleOpen}
        />
      </div>

      <div>
        <div className="posts">
          {showContractsSpinner && (
            <Spinner animation="border" variant="light" className="large-spinner"></Spinner>
          )}
          {events.length > 0 && categories.length > 0 && posts.map((post) => (
            <NFTcontract post={post} categories={categories} events={events} />
          ))}
        </div>
      </div>

      {error && <p className="error">{error}</p>}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2 className="page-title">NFT collection</h2>
          </Typography>
          <div style={{ marginTop: 2 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              <Form>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field
                    name="name"
                    type="text"
                    className="form-control  input-field"
                    placeholder="Name of the NFT collection."
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="symbol">Symbol</label>
                  <Field
                    name="symbol"
                    type="symbol"
                    className="form-control input-field"
                    placeholder="Name of the token. Like BTC, ETH etc."
                  />
                  <ErrorMessage
                    name="symbol"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <Field
                    name="description"
                    type="description"
                    className="form-control input-field"
                    placeholder="Description of the NFT collection."
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Points</label>
                  <Field
                    name="points"
                    type="number"
                    className="form-control  input-field"
                    placeholder="NFT points."
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Event</label>
                  <select
                    className="pdi-dropdown"
                    id="selectedOption"
                    // name="selectedOption"
                    onChange={handleEventChange}
                    // onBlur={formik.handleBlur}
                    value={selectedEvent}
                  >
                    <option value="">Select an option</option>
                    {events.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="description">Category</label>
                  <select
                    className="pdi-dropdown"
                    id="selectedOption"
                    onChange={handleCategoryChange}
                    value={selectedCategory}
                  >
                    <option value="">Select an category</option>
                    {categories.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>

                <img
                  className="thumb-image center"
                  src={nftFileUrl}
                  alt="Very cool NFT."
                  style={{ marginTop: "20px", marginBottom: "10px" }}
                />

                <Button
                  variant="contained"
                  component="label"
                  className="full-width-button-without-span"
                  style={{
                    backgroundColor: "#fff",
                    border: "1px solid #0f65f0",
                    color: "#0f65f0",
                    borderRadius: "20px",
                  }}
                  startIcon={
                    <CloudUploadIcon style={{ marginRight: "10px" }} />
                  }
                >
                  Upload badge image
                  <input
                    id="file"
                    accept="image/*"
                    name="file"
                    type="file"
                    hidden
                    onChange={(event) => {
                      // @ts-ignore: Object is possibly 'null'.
                      let file: File | null = event.currentTarget.files[0];
                      if (file !== null) {
                        const objectUrl = URL.createObjectURL(file);
                        setNftFile(file);
                        setNftFileUrl(objectUrl);
                      }
                    }}
                    className="form-control"
                  />
                </Button>

                <div className="form-group">
                  <button
                    type="submit"
                    className="full-width-button"
                    style={{ marginTop: "2em" }}
                  >
                    <span>
                      {showSpinner && (
                        <Spinner animation="border" variant="light"></Spinner>
                      )}
                      SAVE
                    </span>
                  </button>
                </div>
                {/* {message && (
                  <div className="form-group">
                    <div className="alert alert-danger" role="alert">
                      {message}
                    </div>
                  </div>
                )} */}
              </Form>
            </Formik>{" "}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default NFTcontractList;
