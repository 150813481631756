export default function authHeader() {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr) user = JSON.parse(userStr);

  if (user && user.accessToken) {
    return {
      Authorization: "Bearer " + user.accessToken,
      "Content-Type": "application/json",
    };
  } else {
    return { Authorization: "", "Content-Type": "application/json" }; // for Spring Boot back-end
  }
}
