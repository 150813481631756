import React from "react";
import { Tabs, Tab, Box } from "@mui/material";
import NFTlist from "./NFTlist";
import NFTcontractList from "./NFTcontractList";
import cloudsImageSrc from "../../assets/clouds-cropped.png";
import EventsList from "../events/EventsList";
import Navbar from "../shared/Navbar";
import CategoryList from "./CategoryList";
import TabPanel from "../shared/TabPanel";
import { useLocation, useNavigate } from "react-router-dom";
import ModalBootstrap from "react-bootstrap/Modal";
import hillsAndMedalsIcon from "../../assets/hillsAndMedals.png";
import Imports from "./Imports";

function Wallet() {
  const navigation = useNavigate();
  const [selectedTab, setValue] = React.useState(0);
  const [openAchievement, setOpenAchievement] = React.useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const userStr = localStorage.getItem("user");
  let user: any = null;
  if (userStr) {
    user = JSON.parse(userStr);
  }

  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const achievement = query.get('achievement');
  const achievementpoints = query.get('achievementpoints');

  const handleCloseAchievement = () => {
    setOpenAchievement(false);
    navigation("");
  };

  React.useEffect(() => {
    if (achievement)
      setOpenAchievement(true);
  }, []);

  return (
    <div>
      <div>
        <Navbar />
        <div style={{
          minHeight: "160px",
          backgroundImage: `url(${cloudsImageSrc})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat"
        }}>
          <div>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                aria-label="tabs"
                TabIndicatorProps={{ style: { background: 'none' } }}
                >
                <Tab label="Events" style={{ marginTop: "111px" }} />
                {user.isAdmin && <Tab label="PDI BADGES" style={{ marginTop: "111px" }} />}
                {user.isAdmin && <Tab label="Imports" style={{ marginTop: "111px" }} />}
                {user.isAdmin && <Tab label="Categories" style={{ marginTop: "111px" }} />}
                <Tab label="Badges" style={{ marginTop: "111px" }} />
              </Tabs>
            </Box>
          </div>
        </div>
        {user.isAdmin && (
          <div>
            <TabPanel value={selectedTab} index={0}>
              <EventsList />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <NFTcontractList />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <Imports />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <CategoryList />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <NFTlist />
            </TabPanel>
          </div>
        )}
        {!user.isAdmin && (
          <div>
            <TabPanel value={selectedTab} index={0}>
              <EventsList />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <NFTlist />
            </TabPanel>
          </div>
        )}
      </div>

      {openAchievement && (
        <ModalBootstrap
          show={true}
          onHide={() => handleCloseAchievement()}
        >
          <ModalBootstrap.Header closeButton>
            <ModalBootstrap.Title
              style={{ color: "#06409e", fontSize: "1.2rem" }}
            >
              Achievement
            </ModalBootstrap.Title>
          </ModalBootstrap.Header>
          <ModalBootstrap.Body>
            <div style={{ textAlign: "center" }}>
              <div>{achievement}</div>
              <span style={{
                backgroundColor: "#F5B815",
                borderRadius: "5px",
                padding: "4px",
                paddingLeft: "10px",
                paddingRight: "10px",
                fontSize: "0.85rem",
                fontWeight: "bold"
              }}>
                {achievementpoints}&nbsp; Points
              </span>
            </div>
          </ModalBootstrap.Body>
          <ModalBootstrap.Footer>
            <div>
              <img
                style={{ width: "100%" }}
                src={hillsAndMedalsIcon}
                alt="Badges."
              />
            </div>
          </ModalBootstrap.Footer>
        </ModalBootstrap>
      )}

    </div>
  );
}

export default Wallet;
