import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalBootstrap from "react-bootstrap/Modal";
import QrScanner from "qr-scanner";
import Spinner from "react-bootstrap/esm/Spinner";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SvgIcon from "@mui/icons-material/WarningAmber";

interface QRscannerProperties {
    scannerName: string;
    warning: string;
    setOpenedQrScanner: (newState: boolean) => void;
    processScannedResult: (result: string | undefined) => void;
}


function QRscanner({ scannerName, setOpenedQrScanner, processScannedResult, warning }: QRscannerProperties) {
    const [scanner, setScanner] = React.useState<QrScanner | null>(null);
    const [videoStarted, setVideoStarted] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(true);

    const scanQrCode = async () => {
        let videoElementeRaw = document.querySelector("video");
        if (!videoElementeRaw) return;
        let videoElement: HTMLVideoElement = videoElementeRaw;

        if (!scanner) {
            const qrScanner = new QrScanner(
                videoElement,
                (result) => {
                    setOpenModal(false);
                    handleCloseQrScanner();
                    processScannedResult(result?.data);
                    const mediaStream = videoElement.srcObject;

                    if (mediaStream instanceof MediaStream) {
                        const tracks = mediaStream.getTracks();
                        tracks.forEach(track => {
                            track.stop();
                        });

                        videoElement.srcObject = null;
                    }
                },
                {
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                },
            );
            setScanner(qrScanner);
            await qrScanner.start();
        } else {
            // await scanner?.start();
            // setVideoStarted(true);
        }
        setVideoStarted(true);
    };

    const handleCloseQrScanner = () => {
        cleanUpScanner();
        setOpenedQrScanner(false);
        setVideoStarted(false);
    };

    function cleanUpScanner() {
        if (scanner) {
            scanner.destroy();
            setScanner(null);
        }

        let result = document.querySelector("video");
        if (!result) return;
        let videoElement: HTMLVideoElement = result;
        videoElement.pause();
    }

    return (
        <ModalBootstrap
            show={openModal}
            onHide={handleCloseQrScanner}
            onShow={scanQrCode}
        >
            <ModalBootstrap.Header closeButton>
                <ModalBootstrap.Title
                    style={{ color: "#06409e", fontSize: "1.2rem" }}
                >
                    {scannerName}
                </ModalBootstrap.Title>
            </ModalBootstrap.Header>
            <ModalBootstrap.Body>
                <div id="video-container">
                    <video id="qr-video" style={{ width: "100%" }} webkit-playsinline playsInline></video>
                    <div className="centered-container">
                        {!videoStarted && (
                            <Spinner
                                animation="border"
                                variant="light"
                                className="large-spinner"
                            ></Spinner>
                        )}
                    </div>
                </div>
                {warning && <div style={{ backgroundColor: "#ffb71b", padding: "10px" }}>
                    <SvgIcon style={{ height: "100%", fontSize: "1.5rem", color: "black" }}
                        className="profile-icon"
                        component={ErrorOutlineIcon}
                    />
                    {warning}
                </div>
                }
            </ModalBootstrap.Body>
            <ModalBootstrap.Footer></ModalBootstrap.Footer>
        </ModalBootstrap>
    )
}

export default QRscanner;