/*
Help Page
*/

import { useParams } from "react-router-dom";

//Images
import mintingImage from "../../assets/help/minting.jpeg";
import accessImage from "../../assets/help/access.jpeg";
import homeImage from "../../assets/help/home.jpeg";
import loginImage from "../../assets/help/login.jpeg";
import qrCodeIconImage from "../../assets/help/qr_code_icon.jpeg";
import scannerImage from "../../assets/help/scanner.jpeg";
import qrImage from "../../assets/help/qr.jpeg";
import claimImage from "../../assets/help/claim.jpeg";
import walletImage from "../../assets/help/wallet.jpeg";
import detailImage from "../../assets/help/detail.jpeg";
import osScannerImage from "../../assets/help/os_scanner.jpeg";
import claimUCImage from "../../assets/help/claim_uc.jpeg";
import { margin } from "@mui/system";

//must send a proper UUID to show the help content
const HELP_UUID: String = "8d57e342-1ec4-4e8b-89e6-37c3160ab0e4";

function Help() {

  //ger the user params (UUID)
  let { id } = useParams();

  if (id !== HELP_UUID) {
    //didn't pass the correct UUID such as http://localhost:3000/help/<UUID>
    return (<div>Not Authorized!</div>);

  } else {

    //show the help content
    return (
      <div style={{ backgroundColor: 'white' }}>
        <div style={{ textAlign: "left", margin: "1.5em" }}>

          <div style={{ textAlign: "center" }}>
            <h1>Amplify Rewards</h1>
            <p></p>
            <img src={mintingImage} alt="NFTs." />
          </div>


          {/* Overview section */}
          <div>
            <br></br>
            <h2>Overview</h2>
            The Amplify Rewards is a prototype of PDI’s first ever badge wallet. The wallet is a web-based application capable of minting on the AVAX blockchain. Users can leverage any device such as a smartphone or a tablet to mint a new badge using a QR code.
            At UC22, PDI customers have an opportunity to scan QR codes at select sessions (and throughout the conference) to mint their very own badges. Each badge can be redeemed for PDI bucks at the Big Night Out. After UC, users will retain the badges in their wallet.
          </div>

          {/* Opening Wallet section */}
          <div>
            <br></br>
            <h2>Opening the Wallet</h2>
            To use the wallet, simply open it within the PDI UC app by tapping on “Access your Amplify Rewards”.
            <p></p>
            <div style={{ textAlign: "center" }}>
              <img src={accessImage} alt="Accessing the wallet." />
            </div>
            <br></br>
            <div>
              The app will automatically log you in based on the UC registration. At first, the wallet will be empty, but you will see some NFTs there once you claim them.
            </div>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <img src={homeImage} alt="Wallet home" />
            </div>
            <br></br>
            If you have any issues accessing the wallet from the UC app, you can always access it by navigating to <a href={'https://wallet.platform.pdisoftware.com'}>https://wallet.platform.pdisoftware.com</a> in your browser. When accessing directly, you will have to login. All UC22 participants have been pre-registered and you can login using your email and the last 4 digits of your phone number as a PIN.
            <br></br>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <img src={loginImage} alt="Login to the wallet" />
            </div>
          </div>

          {/* Minting NFT using PDI Wallet section */}
          <div>
            <br></br>
            <h2>Minting an NFT using the PDI Wallet</h2>
            Scan a QR code from the wallet app by tapping on the QR code icon in the top right corner <img src={qrCodeIconImage} alt="QR Scanner" />. Doing so will open the QR code scanner. Simply point the camera towards the QR code to scan.
            <div style={{ textAlign: "center" }}>
              <br></br>
              <img src={scannerImage} alt="Scanner screen" />
              <img src={qrImage} alt="QR code screen" />
            </div>
            <br></br>
            Once scanned, you should automatically see the claim screen. Simply tap on “Claim your NFT”. Please note that scanning from a distance can sometimes be challenging. You may want to either zoom in or get closer to the QR code. If that still does not work, you can also scan the QR code using your built-in camera app.
            <p></p>

            <div style={{ textAlign: "center" }}>
              <img src={claimImage} alt="Claim NFT" />
            </div>
            <p></p>
            When the NFT is minted, you will see it in your wallet.
            <p></p>
            <div style={{ textAlign: "center" }}>
              <img src={walletImage} alt="Claim NFT" />
            </div>
            <p></p>
            You can always click on each NFT to see details.
            <p></p>
            <div style={{ textAlign: "center" }}>
              <img src={detailImage} alt="Claim NFT" />
            </div>
          </div>

          {/* Minting NFT using smartphone camera section */}
          <div>
            <br></br>
            <h2>Minting an NFT using smartphone camera</h2>
            If you experience issues with the built-in QR code scanner, you can always use your smartphone camera app to scan the QR code. Once scanned, the app will open to a screen where you can claim the NFT. As with opening the wallet outside of the UC app, you will have to login to mint.
            <p></p>
            <div style={{ textAlign: "center" }}>
              <img src={osScannerImage} alt="Scanner screen" />
            </div>
          </div>

          {/* Minting from UC app section */}
          <div style={{ textAlign: "left", margin: "1em" }}>
            <br></br>
            <h2>Minting from UC App</h2>
            Each PDI UC22 conference participant can also claim the main UC NFT simply by clicking on the “Claim your first UC22 NFT” from the UC app.
            <p></p>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <img src={claimUCImage} alt="Scanner screen" />
            </div>
          </div>

          {/* NFT FAQ section */}
          <div>
            <br></br>
            <h2>NFT FAQ</h2>

            <p></p>
            <b>What is an NFT?</b>
            <br></br>
            NFT stands for a non-fungible token. NFT is simply a digital asset that exists on a blockchain. The owners of the NFT can verifiably prove that they indeed own it.

            <p></p>
            <b>Why should I mint an NFT at UC?</b>
            <br></br>
            Each NFT minted at UC22 can be redeemed for $1000 PDI bucks. Plus, it’s kind of fun.

            <p></p>
            <b>How can I use NFTs in my business?</b>
            <br></br>
            NFTs are very new. Brands are still exploring how they can be leveraged. There are many use cases. Some of those use cases are to engage consumers in a loyalty program or to create a special experience. Register for the UC22 “Crypto and Metaverse” session to find out more.

            <p></p>
            <b>Do I get to keep the NFTs that I mint?</b>
            <br></br>
            Yes. PDI wallet created a new account on the AVAX blockchain. You can continue to leverage the PDI Wallet or use another crypto wallet that supports NFTs. Please note that the current version of the wallet does not reveal your blockchain secret key, but that’s something we intend to add later.

            <p></p>
            <b>Who owns the NFT I mint?</b>
            <br></br>
            You own the record of ownership on the blockchain, but PDI still retains the copyright to the NFT image.

            <p></p>
            <b>Is the NFT minted on a blockchain?</b>
            <br></br>
            Yes. Avalanche Network (AVAX)

            <p></p>
            <b>Can I open the NFT in a non-PDI wallet?</b>
            <br></br>
            You can use any NFT wallet that supports ERC-721 tokens on AVAX. Please, note that the current version of PDI NFTs do not support viewing of the actual image in a wallet such as Metamask nor does it reveal the secret key.

            <p></p>
            <b>Can I transfer the NFT to someone else?</b>
            <br></br>
            No. The UC22 NFTs are non-transferable.

            <p></p>
            <b>Can I mint the same NFT more than once?</b>
            <br></br>
            No.

            <p></p>
            <b>Is there is a limit as to how many NFTs I can mint at UC22</b>
            <br></br>
            We will have approximately 20 NFTs at UC22 and you can mint them all if you attend the right sessions and can find them. 😊
          </div>


          <div>
            <br></br>
            <h2>Wallet FAQ</h2>

            <p></p>
            <b>How can I access the wallet without scanning a QR code?</b>
            <br></br>
            Simply open the wallet from your UC app by tapping “Access your PDI Wallet”. Alternatively, you can always access the wallet by navigating to <a href={'https://wallet.platform.pdisoftware.com'}>https://wallet.platform.pdisoftware.com</a> in your browser, but you will have to login. All UC22 participants have been pre-registered and you can login using your email and the last 4 digits of your phone number as a PIN.

            <p></p>
            <b>Can I bookmark the PDI wallet address?</b>
            <br></br>
            Yes.

            <p></p>
            <b>Can I add the wallet to the home screen on iOS or Android?</b>
            <br></br>
            Yes. Most mobile browsers support users to add web applications to home screens.

            <p></p>
            <b>How do I login to the wallet?</b>
            <br></br>
            When accessing directly, you will have to login. All UC22 participants have been pre-registered. Ask conference personnel for your credentials.

            <p></p>
            <b>I’m not able to login</b>
            <br></br>
            If the Wallet does not automatically log you in after opening it from the UC app or if you’re unable to login separately, please come and see the PDI UC support staff. Alternatively, you create a new account with a different email.

            <p></p>
            <b>I chose to create an account, but I’m not able to see the verification email</b>
            <br></br>
            The emails come from pdi-wallet@pdisoftware.com. They may end-up in a junk folder. Similarly, on Gmail, these emails are often automatically tagged as Promotions or Updates. If you’re still having issues, come and see the PDI UC support staff.

            <p></p>
            <b>Why do I need an account and a PIN?</b>
            <br></br>
            We’re enabled authentication directly from the UC app; however, if you’d like to use the wallet in a standalone fashion, you will have to authenticate. We think it’s important to protect each account from any malicious activity.

            <p></p>
            <b>Do you support biometrics such as FaceID?</b>
            <br></br>
            Not at the moment.

            <p></p>
            <b>Will the PDI Wallet continue to operate after UC22</b>
            <br></br>
            Possibly. However, the PDI Wallet is a proof of concept. We are exploring web3 and crypto with our customers to show the Art of The Possible and hope that customers find it valuable.

            <p></p>
            <b>Is PDI Wallet on a product roadmap?</b>
            <br></br>
            Not at the moment. However, if you find it valuable, please reach out to <a href={'mailto://vcollak@pdisoftware.com'}>vcollak@pdisoftware.com</a>

            <p></p>
            <b>Can the PDI Wallet function without an Internet connection in an Airplane mode? </b>
            <br></br>
            No.

          </div>
        </div >
      </div >
    );
  }

}

export default Help;
