import React from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from "react-router-dom";
import { IEvent } from "../../interfaces/IEvent";
import EventsService from "../../services/Events.service";
import SvgIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import boostIcon from "../../assets/boost-icon.png";
import NFTsForCategory from "./NFTsForCategory";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalBootstrap from "react-bootstrap/Modal";
import { Tabs, Tab, Box } from "@mui/material";
import TabPanel from "../shared/TabPanel";
import Leaderboard from "../leaderboard/Leaderboard";
import amplifyRewardsIcon from "../../assets/Amplify_Rewards.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QRscanner from "../shared/QRscanner";
import MinterDialog from "../wallet/MinterDialog";
import Profile from "../userManagement/Profile";


function EventDetail() {
    const userStr = localStorage.getItem("user");
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const { id } = useParams();
    const [event, setEvent]: [IEvent, (event: IEvent) => void] = React.useState({} as IEvent);
    const [openedRedeemDialog, setOpenedRedeemDialog] = React.useState(false);
    const [areNftsRedeemed, setAreNftsRedeemed] = React.useState<boolean>();
    const [openedMinter, setOpenedMinter] = React.useState(false);
    const [openedQrScanner, setOpenedQrScanner] = React.useState(false);
    const [openedProfile, setOpenedProfile] = React.useState(false);
    const [nftUrl, setNftUrl] = React.useState<string>();
    const [selectedTab, setValue] = React.useState(0);
    const [claimedNftContractId, setClaimedNftContractId] = React.useState<string>();
    const [acquiredNfts, setAcquiredNfts] = React.useState<number>(0);
    const [acquiredPoints, setAcquiredPoints] = React.useState<number>(0);

    const navigation = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if (!id) {
        throw "Id not provided.";
    }

    let user: any = null;
    if (userStr) {
        user = JSON.parse(userStr);
    }

    React.useEffect(() => {
        setLoading(true);

        EventsService
            .getEventDetail(id, user)
            .then(x => {
                setEvent(x.data);
                setLoading(false);
                setAreNftsRedeemed(x.data.areNftsRedeemed);
                setAcquiredNfts(x.data.acquiredNfts);
                setAcquiredPoints(x.data.acquiredPoints);
            }).catch(error => {
                if (error.response.status === 401) {
                    navigation(`/login/?url=${window.location.pathname}`);
                }
                setLoading(false);
            });
    }, []);

    const onRedeemPoints = (): void => {
        EventsService.redeemNfts(id, user.email).then(x => {
            if (x.status == 200) {
                setAreNftsRedeemed(true);
            } else {
                setAreNftsRedeemed(false);
            }
        });
    };

    function processScannedResult(result: string | undefined) {
        if (result) {
            try {
                setNftUrl(result);
                setOpenedMinter(true);
            } catch (error) {
                // todo: show error
            }
        } else {
            // todo: show error
        }
    }

    function handleNftClaimed(badges: any) {
        let items = badges.filter((x: any) => x.id != null);
        let points = items.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.points, 0);

        setAcquiredNfts(acquiredNfts + items.length);
        setAcquiredPoints(acquiredPoints + points);

        items.forEach((badge: any) => {
            setClaimedNftContractId(badge.id);
            let item = event.items.find(x => x.categoryId == badge.categoryId);
            if (item) {
                item.acquiredNfts++;
                item.acquiredPoints += badge.points;
            }
        });
    }

    function handleCloseMinter() {
        setOpenedMinter(false);
        window.location.reload();
    }

    return (
        <div style={{ height: "100%" }}>
            {loading && (
                <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                    <Spinner animation="border" variant="primary" className="large-spinner"></Spinner>
                </div>
            )}

            {!loading && (
                <div style={{ marginTop: "20px", height: "100%" }}>
                    {openedQrScanner && (
                        <QRscanner scannerName="Badge Scanner" warning="" setOpenedQrScanner={setOpenedQrScanner} processScannedResult={processScannedResult}></QRscanner>
                    )}

                    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "30px", textAlign: "center" }}>
                        <img
                            style={{
                                width: "100%",
                                textAlign: "center",
                                maxWidth: "245px",
                                maxHeight: "36px"
                            }}
                            src={amplifyRewardsIcon}
                            alt="Badges."
                        />
                    </div>

                    <div>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tabs
                                value={selectedTab}
                                onChange={handleChange}
                                aria-label="tabs"
                                TabIndicatorProps={{ style: { background: 'none' } }}
                            >
                                <Tab label="BADGES" />
                                <Tab label="LEADERBOARD" />
                            </Tabs>

                            <div className="icon-container" style={{ padding: "5px", paddingRight: "20px", color: "#06409e", cursor: "pointer", display: "inlineBlock", fontWeight: "bold" }}>
                                {!areNftsRedeemed && (
                                    <div onClick={() => setOpenedRedeemDialog(true)}>
                                        Redeem
                                    </div>
                                )}
                                {areNftsRedeemed && (
                                    <div>
                                        <span style={{ color: "gray" }}>
                                            Redeemed
                                        </span>
                                    </div>
                                )}
                            </div>

                        </Box>
                    </div>

                    <div style={{ height: "100%", backgroundColor: "white" }}>
                        <TabPanel value={selectedTab} index={0}>
                            <div style={{ backgroundColor: "white" }}>
                                <div style={{ fontWeight: "bold", paddingLeft: "20px", paddingTop: "10px", color: "#07003D" }}>
                                    Eligible badges for this event
                                </div>
                                <div style={{ paddingLeft: "20px" }}>
                                    <div style={{ display: "inline-block", backgroundColor: "#56D1F4", borderRadius: "5px", padding: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px", marginTop: "5px" }}>
                                        {acquiredNfts}&nbsp;/&nbsp;{event.nftsCount} Badges
                                    </div>
                                    <div style={{ display: "inline-block", backgroundColor: "#F5B815", borderRadius: "5px", padding: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px", marginTop: "5px" }}>
                                        {acquiredPoints}&nbsp;/&nbsp;{event.totalPoints} Points
                                    </div>
                                    {event.isBoosted &&
                                        <div style={{ display: "inline-block", backgroundColor: "#3DB54E", borderRadius: "5px", padding: "3px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "100", marginRight: "5px", marginTop: "5px", whiteSpace: "nowrap" }}>
                                            <img src={boostIcon} alt="Boost icon." style={{ height: "18px" }} />
                                            <span style={{ color: "white" }}>Boosted</span>
                                        </div>
                                    }
                                </div>

                                <div style={{ borderBottom: "1px solid #949F9F", marginTop: "20px" }}>
                                    <span style={{ color: "#949F9F", fontWeight: "bold", fontSize: "0.9rem", paddingLeft: "20px" }}>BADGE CATEGORIES</span>
                                </div>
                                {event && event.items.map((categoryDetail) => (
                                    <NFTsForCategory categoryDetail={categoryDetail} eventName={event.name} eventId={event.id} claimedNftContractId={claimedNftContractId} />
                                ))}

                                <div style={{ marginTop: "10px", textAlign: "right" }}>
                                    <button
                                        onClick={() => setOpenedQrScanner(true)}
                                        style={{
                                            fontWeight: "bold",
                                            background: "#99007E",
                                            border: "1px solid #99007E",
                                            padding: "8px",
                                            marginTop: "14px",
                                            color: "#1226AA",
                                            borderRadius: "50%",
                                            margin: "20px",
                                            position: "fixed",
                                            bottom: "0px",
                                            right: "25px",
                                        }}>
                                        <span>
                                            <SvgIcon
                                                className="profile-icon"
                                                fontSize="large"
                                                component={QrCodeScannerIcon}
                                                style={{ height: "0.86em", margin: "5px", color: "white" }}
                                            />
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={selectedTab} index={1}>
                            <div>
                                <Leaderboard eventId={event.id}></Leaderboard>
                            </div>
                        </TabPanel>
                    </div>
                </div>
            )
            }

            {openedRedeemDialog && (
                <ModalBootstrap
                    show={true}
                    onHide={() => setOpenedRedeemDialog(false)}
                >
                    <ModalBootstrap.Header closeButton>
                    </ModalBootstrap.Header>
                    <ModalBootstrap.Body>
                        <div style={{ textAlign: "center" }}>
                            <div style={{ color: "#07003D", fontWeight: "bold", fontSize: "17px", marginBottom: "13px" }}>
                                {!areNftsRedeemed && (
                                    <span>Redeem Badge Points for PDI Bucks</span>
                                )}

                                {areNftsRedeemed && (
                                    <span>You are all set!</span>
                                )}
                            </div>

                            <span style={{
                                backgroundColor: "#F5B815",
                                borderRadius: "5px",
                                padding: "4px",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                fontSize: "0.85rem",
                                fontWeight: "bold"
                            }}>
                                {event.acquiredPoints}&nbsp;/&nbsp;{event.totalPoints} Points
                            </span>

                            {event.isBoosted && (
                                <span style={{
                                    backgroundColor: "#3DB54E",
                                    borderRadius: "5px",
                                    padding: "4px",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    fontSize: "0.85rem",
                                    fontWeight: "100",
                                    marginLeft: "5px",
                                    marginRight: "5px",
                                }}>
                                    <img src={boostIcon} alt="Boost icon." />
                                </span>
                            )}

                            <div style={{ marginTop: "13px" }}>
                                {!areNftsRedeemed && (
                                    <span>Ready to redeem those badge points for PDI Bucks and enjoy Big Night Out?</span>
                                )}

                                {areNftsRedeemed && (
                                    <span>Thanks for playing along and supporting Feeding America by participating in Amplify Rewards!</span>
                                )}
                            </div>
                        </div>
                    </ModalBootstrap.Body>
                    <ModalBootstrap.Footer>
                        <button
                            type="submit"
                            disabled={areNftsRedeemed}
                            onClick={onRedeemPoints}
                            style={{
                                fontWeight: "bold",
                                width: "100%",
                                background: "#99007E",
                                border: "1px solid #99007E",
                                borderRadius: "5px",
                                padding: "8px",
                                marginTop: "14px",
                                color: "white"
                            }}>
                            <span>
                                {loading && (
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                    ></Spinner>
                                )}
                                Redeem
                            </span>
                        </button>
                    </ModalBootstrap.Footer>
                </ModalBootstrap>
            )}

            <ModalBootstrap
                show={openedMinter}
                onHide={() => handleCloseMinter()}>
                <ModalBootstrap.Header closeButton style={{ backgroundColor: "#d8e2f0" }}>
                </ModalBootstrap.Header>
                <ModalBootstrap.Body style={{ backgroundColor: "#d8e2f0" }}>
                    <MinterDialog nftClaimUrl={nftUrl} userId={undefined} handleNftClaimed={handleNftClaimed} handleCloseMinter={handleCloseMinter} />
                </ModalBootstrap.Body>
                <ModalBootstrap.Footer style={{ backgroundColor: "#d8e2f0" }}>
                </ModalBootstrap.Footer>
            </ModalBootstrap>

            <ModalBootstrap
                show={openedProfile}
                onHide={() => setOpenedProfile(false)}>
                <ModalBootstrap.Header closeButton style={{ backgroundColor: "#d8e2f0" }}>
                    {/* <span style={{ color: "#06409e", fontSize: "1.3em", fontWeight: "600"}}>Profile</span> */}
                </ModalBootstrap.Header>
                <ModalBootstrap.Body style={{ backgroundColor: "#d8e2f0" }}>
                    <Profile />
                </ModalBootstrap.Body>
                <ModalBootstrap.Footer style={{ backgroundColor: "#d8e2f0" }}>
                </ModalBootstrap.Footer>
            </ModalBootstrap>
        </div >
    );
}

export default EventDetail;
