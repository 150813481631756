
const local = {
  API_BASE_URL: "http://localhost:3005",
  FRONTEND_URL: "http://localhost:3000",
};

const dev = {
  API_BASE_URL: "https://api.wallet.dev.platform.pdisoftware.com",
  FRONTEND_URL: "https://amplifyrewards.dev.pws.pditechnologies.com",
};

const prod = {
  API_BASE_URL: "https://amplifyrewards.api.pws.pditechnologies.com",
  FRONTEND_URL: "https://amplifyrewards.pws.pditechnologies.com",
};

const envconfig = dev;

export default envconfig;