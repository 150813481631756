import React from "react";
import ExpandButton from "../shared/ExpandButton";
import EventNFT from "./EventNFT";
import EventsService from "../../services/Events.service";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import { IEventNFT } from "../../interfaces/IEvent";

function NFTsForCategory({ categoryDetail, eventName, eventId, claimedNftContractId }: any): JSX.Element {
    const userStr = localStorage.getItem("user");
    let user: any = null;
    if (userStr) {
        user = JSON.parse(userStr);
    }

    const [toggle, setToggle]: [boolean, (toggle: boolean) => void] = React.useState<boolean>(false);
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(false);
    const [images, setImages]: [IEventNFT[], (eventNfts: IEventNFT[]) => void] = React.useState<IEventNFT[]>([]);

    const navigation = useNavigate();

    function handleToggle() {
        if (!toggle && (!images || images.length == 0)) {
            setLoading(true);

            EventsService
                .getCategoryBadges(eventId, categoryDetail.categoryId, user)
                .then(x => {
                    setImages(x.data);
                    setLoading(false);
                }).catch(error => {
                    if (error.response.status === 401) {
                        navigation(`/login/?url=${window.location.pathname}`);
                    }
                    setLoading(false);
                });
        }

        setToggle(!toggle);
    }

    if (claimedNftContractId) {
        if (images.length > 0) {
            let image = images.find(image => image.nftContractId == claimedNftContractId);
            if (image && !image.isAcquired) {
                image.isAcquired = true;
            }
        }
    }

    return <div>
        <div style={{ width: "100%", paddingBottom: "10px", paddingRight: "5px", paddingLeft: "20px" }}
            onClick={() => handleToggle()}
        >
            <div style={{ width: "100%" }}>
                <div style={{ display: "inline-block" }}>
                    <div style={{ fontWeight: "bold", paddingTop: "10px" }}>{categoryDetail.category}</div>
                    <div style={{ backgroundColor: "#56D1F4", width: "fit-content", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px" }}>
                        {categoryDetail.acquiredNfts}&nbsp;/&nbsp;{categoryDetail.nftsCount} Badges
                    </div>
                </div>
                <div style={{ float: "right", paddingTop: "30px" }}>
                    <ExpandButton />
                </div>
            </div>
        </div>
        <div style={{ paddingLeft: "20px" }}>
            {loading && (
                <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                    <Spinner animation="border" variant="primary" className="large-spinner"></Spinner>
                </div>
            )}

            {toggle && images && (
                images.map((item: any) => (
                    <EventNFT eventNFT={item} eventName={eventName}></EventNFT>
                ))
            )}
        </div>
        <div style={{ width: "100%", borderBottom: "1px solid lightgrey", paddingBottom: "10px" }}></div>
    </div >;
}

export default NFTsForCategory;