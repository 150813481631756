import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import {BrowserRouter as Router} from 'react-router-dom';
import UserService from "./services/User.service";

UserService.startTokenExpirationCheck();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Router>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </Router>
);
