import React from "react";
import { IEventNFT } from "../../interfaces/IEvent";
import NFTdetail from "../shared/NFTdetail";

interface Properties {
    eventNFT: IEventNFT;
    eventName: string;
}

function EventNFT({ eventNFT, eventName }: Properties): JSX.Element {
    const [open, setOpen] = React.useState(false);

    return <div
        style={{ display: "inline-block", width: "fit-content" }}
    >
        <img
            onClick={() => { if(eventNFT.isAcquired)setOpen(true)}}
            style={{
                display: "inline", marginTop: "20px", marginRight: "20px",
                marginBottom: "5px", filter: eventNFT.isAcquired ? "grayscale(0)" : "grayscale(1)",
                opacity: eventNFT.isAcquired ? "1" : "0.2"
            }}
            className="thumb-image"
            src={`data:image/png;base64,${eventNFT.image}`}
            alt="" />
        <div style={{ textAlign: "center", marginRight: "20px", fontSize: "0.85rem" }}>{eventNFT.points}&nbsp;pts</div>

        {open &&
            <NFTdetail image={eventNFT.image} name={eventNFT.name} description={eventNFT.description} points={eventNFT.points} eventName={eventName} setOpen={setOpen}></NFTdetail>
        }
    </div>
}

export default EventNFT;