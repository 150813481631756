import axios from "axios";
import envconfig from "../environment-config";
import authHeader from "./Auth.header";
import { IEvent, IEventNFT } from "../interfaces/IEvent";
import { IUser } from "../interfaces/IUser";
import { ILeaderboard } from "../interfaces/ILeaderboard";

const EVENTS_API_URL = `${envconfig.API_BASE_URL}/events`;

class EventsService {
  async getEvents() {
    return axios.get<IEvent[]>(
      `${EVENTS_API_URL}`,
      {
        headers: authHeader(),
      }
    )
  }

  async getEventsWithUserStats(user: IUser) {
    return axios.get<IEvent[]>(
      `${EVENTS_API_URL}/stats?email=${user.email}`,
      {
        headers: authHeader(),
      }
    )
  }

  async getEventDetail(eventId: string, user: IUser) {
    return axios.get<IEvent>(
      `${EVENTS_API_URL}/detail/${eventId}?email= ${user?.email}`,
      {
        headers: authHeader(),
      }
    )
  }

  async getCategoryBadges(eventId: string, categoryId: string, user: IUser) {
    return axios.get<IEventNFT[]>(
      `${EVENTS_API_URL}/images/${eventId}/${categoryId}?email= ${user?.email}`,
      {
        headers: authHeader(),
      }
    )
  }


  async addEvent(event: any) {
    return axios
      .post(
        `${EVENTS_API_URL}`,
        {
          name: event.name,
          location: event.location,
          startDate: event.startDate,
          endDate: event.endDate
        },
        {
          headers: authHeader(),
        }
      )
  }

  async deleteEvent(id: string) {
    return axios
      .delete(
        `${EVENTS_API_URL}?id=${id}`,
        {
          headers: authHeader(),
        }
      )
  }

  async redeemNfts(eventId: string, email: string) {
    return axios
      .post(
        `${EVENTS_API_URL}/redeem`,
        {
          eventId: eventId,
          email: email
        },
        {
          headers: authHeader(),
        }
      )
  }

  async getLeaderboard(eventId: string) {
    return axios
      .post(
        `${EVENTS_API_URL}/${eventId}/leaderboard/`,
        {
          eventId: eventId
        },
        {
          headers: authHeader(),
        }
      )
  }

  formatDate(from: any, to: any): string {
    const date1 = new Date(from);
    const date2 = new Date(to);

    const month1 = date1.toLocaleDateString('en-US', { month: 'long' });
    const month2 = date2.toLocaleDateString('en-US', { month: 'long' });
    const day1 = date1.getDate();
    const day2 = date2.getDate();
    const year = date1.getFullYear();

    let formattedDate;

    if (month1 === month2) {
      if (day1 === day2) {
        formattedDate = `${month1} ${day1}, ${year}`;
      }
      else {
        formattedDate = `${month1} ${day1} - ${day2}, ${year}`;
      }
    } else {
      formattedDate = `${month1} ${day1} - ${month2} ${day2}, ${year}`;
    }

    return formattedDate;
  }
}

export default new EventsService();
