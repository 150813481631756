import axios from "axios";
import React from "react";
import { useParams } from "react-router-dom";
import envconfig from "../../environment-config";
import { IContract } from "../../interfaces/IContract";
import authHeader from "../../services/Auth.header";
import UserService from "../../services/User.service";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import localStorageService from "../../services/LocalStorage.service";

function Minter() {
  const navigation = useNavigate();
  var user = UserService.getCurrentUser();

  let { id, userId } = useParams();

  const [nftContract, setnftContract]: [
    IContract,
    (nftContract: IContract) => void
  ] = React.useState({} as IContract);

  const [loading, setLoading]: [boolean, (loading: boolean) => void] =
    React.useState(false);

  const [message, setMessage]: [string, (message: string) => void] =
    React.useState("");

  const [successful, setSuccessful]: [boolean, (loading: boolean) => void] =
    React.useState(false);

  const [achievement, setAchievement]: [string, (achievement: string) => void] =
    React.useState("");

  const [achievementPoints, setAchievementPoints]: [number, (achievementPoints: number) => void] =
    React.useState(0);

  const [openAchievement, setOpenAchievement]: [boolean, (openAchievement: boolean) => void] =
    React.useState(false);

  const [achievementImage, setAchievementImage]: [string, (achievementImage: string) => void] =
    React.useState("");

  const [isBadgeClaimed, setIsBadgeClaimed]: [boolean, (isBadgeClaimed: boolean) => void] =
    React.useState(false);


  React.useEffect(() => {
    axios
      .get<IContract>(`${envconfig.API_BASE_URL}/contracts/${id}`, {
        headers: authHeader(),
      })
      .then((response) => {
        setnftContract(response.data);
      })
      .catch((ex) => {
        console.log(ex);
        if (ex.response.status == 400)
          setMessage(ex.message);
        if (ex.response.status == 500)
          setMessage("Failed to get Badge.");
      });
  }, [id]);

  if (!id) {
    return <div className={"alert alert-danger"}>Failed to load Badge.</div>;
  }

  if (message) {
    return <div className={"alert alert-danger"}>{message}</div>;
  }

  const claimNFT = () => {
    setLoading(true);

    if (user != null) {
      return axios
        .post(
          `${envconfig.API_BASE_URL}/nfts/mint`,
          {
            contractAddress: nftContract.address,
            wallet: user.wallet,
            mintOnBlockchain: false
          },
          {
            headers: authHeader(),
          }
        )
        .then((response) => {
          let achievement = response.data.achievement;

          setLoading(false);
          setMessage("");
          setSuccessful(true);
          setIsBadgeClaimed(true);

          if (achievement) {
            setOpenAchievement(true);
            setAchievement(achievement.message);
            setAchievementPoints(achievement.points);
            setAchievementImage(achievement.image);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorageService.clearCache();
            navigation(`/login/?url=${window.location.pathname}`);
          }

          setLoading(false);
          setMessage(error.response.data.message);
          setSuccessful(false);
          navigation("/wallet/event/fce91e23-222f-46bd-87a8-6c7f224ca49c");
        });
    } else {
      if (userId) {
        return axios
          .post(
            `${envconfig.API_BASE_URL}/nfts/mintWithId/`,
            {
              externalUserId: userId,
              contractAddress: nftContract.address,
              mintOnBlockchain: false
            },
            {
              headers: authHeader(),
            }
          )
          .then((response) => {
            let achievement = response.data.achievement;

            setLoading(false);
            // todo: check eligible nfts, where does this one redirects to
            navigation(`/${userId}`);
            setMessage("");
            setSuccessful(true);
          })
          .catch((error) => {
            setLoading(false);
            setMessage(error.response.data.message);
            setSuccessful(false);
            navigation("/wallet/event/fce91e23-222f-46bd-87a8-6c7f224ca49c");
          });
      } else {
        localStorageService.AddNftToMint(nftContract);
        navigation("/temporary-wallet/");
      }
    }
  };

  const closeMinter = () => {
    navigation("/wallet/event/fce91e23-222f-46bd-87a8-6c7f224ca49c");
  }

  return (
    <div>
      {openAchievement && (
        <div>
          {/* <div style={{ color: "#06409e", fontSize: "1.2rem" }}>
            {achievement}
          </div> */}
          <div>
            <div style={{ textAlign: "center" }}>
              <div style={{ marginBottom: "10px" }}>{achievement}</div>

              <span style={{
                backgroundColor: "#F5B815",
                borderRadius: "5px",
                padding: "4px",
                paddingLeft: "10px",
                paddingRight: "10px",
                fontSize: "0.85rem",
                fontWeight: "bold"
              }}>
                {achievementPoints}&nbsp; Points
              </span>

              <div style={{ marginTop: "30px" }}>
                <img
                  className="mint-nft-thumb"
                  src={`data:image/png;base64,${achievementImage}`}
                  alt=""
                />
              </div>

            </div>
          </div>
        </div>
      )}

      {!openAchievement && (
        <div className="our-card">
          <p className="page-action" style={{ color: "#100E3A" }}>Collect your badge</p>
          <div
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingBottom: "20px",
              zIndex: "5",
            }}
          >
            <div
              style={{
                backgroundColor: "white",
                marginBottom: "20px",
                textAlign: "center",
                padding: "20px",
                borderRadius: "5px",
              }}
            >
              {nftContract.image && (
                <img
                  className="mint-nft-thumb"
                  src={`data:image/png;base64,${nftContract.image}`}
                  alt=""
                />
              )}
              {!nftContract.image && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "auto",
                  }}
                  className="mint-nft-thumb"
                >
                  <Spinner animation="border" variant="primary"></Spinner>
                </div>
              )}

              <div>
                <div className="center nft-detail-title" style={{ color: "#100E3A", fontSize: "1.4rem", fontWeight: "bold" }}>
                  {nftContract.name}
                </div>
                <div style={{ backgroundColor: "lightgray", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", width: "fit-content", marginLeft: "auto", marginRight: "auto", marginTop: "10px", marginBottom: "10px" }}>
                  {nftContract?.category?.name}
                </div>
              </div>

              <div>
                {!nftContract.description && (
                  <div style={{ fontSize: "0.9rem" }}>
                    {nftContract.description}
                  </div>
                )}
                <div style={{ backgroundColor: "#F5B815", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", width: "fit-content", marginLeft: "auto", marginRight: "auto", marginTop: "10px", marginBottom: "10px" }}>
                  {nftContract.points} Points
                </div>
              </div>

              <div>{nftContract.description}</div>
            </div>

            {!isBadgeClaimed && (
              <button
                className="full-width-button"
                onClick={claimNFT}
                disabled={loading}
                style={{ backgroundColor: "#99007E", color: "white", borderRadius: "5px" }}
              >
                <span>
                  {loading && <Spinner animation="border" variant="light"></Spinner>}
                  Collect badge
                </span>
              </button>
            )}

            {isBadgeClaimed && (
              <button
                className="full-width-button"
                onClick={closeMinter}
                disabled={loading}
                style={{ backgroundColor: "#3DB54E", color: "white", borderRadius: "5px" }}
              >
                <span>
                  {loading && <Spinner animation="border" variant="light"></Spinner>}
                  Collected! Continue
                </span>
              </button>
            )}


            {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Minter;
