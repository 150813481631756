import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";
import UserService from "../../services/User.service";
import Spinner from "react-bootstrap/esm/Spinner";
import emailImageSrc from "../../assets/email-checked.png";
import cloudsImageSrc from "../../assets/clouds-clean.png";

export default function CredentialsVerification() {
  const useParamsFnc = useParams();
  const navigation = useNavigate();

  return (
    <CredentialsVerificationComponent useParamsFnc={useParamsFnc} navigation={navigation} />
  );
}

type Props = {
  useParamsFnc: any;
  navigation: NavigateFunction;
};

type State = {
  loading: boolean;
  pin: string;
  email: string;
  successful: boolean;
  message: string;
  verificationToken: string;
  isEmailVerified: boolean | null;
  failedToVerifyEmail: boolean | null;
  isPinVerified: boolean;
};

class CredentialsVerificationComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSetPin = this.handleSetPin.bind(this);
    this.handleSetVerifiedPin = this.handleSetVerifiedPin.bind(this);
    let { email, token } = props.useParamsFnc;

    this.state = {
      loading: false,
      pin: "",
      email: email,
      verificationToken: token,
      successful: false,
      message: "",
      isEmailVerified: null,
      failedToVerifyEmail: null,
      isPinVerified: false,
    };
  }

  validationSchema() {
    return Yup.object().shape({
      pin: Yup.string().required("This field is required!") && Yup.string().matches(/^\d+$/, 'PIN must contain only digits.'),
    });
  }

  handleSetPin(formValue: { email: string; pin: string }) {
    const { pin } = formValue;
    this.setState({
      message: "",
      pin: pin
    });
  }

  handleSetVerifiedPin(formValue: { email: string; pin: string }) {
    const { email, pin } = formValue;
    this.setState({
      message: "",
      successful: false,
    });

    if (this.state.pin !== pin) {
      this.setState({
        message: "Incorrect PIN.",
        successful: false,
      });
      return;
    }

    UserService.setPin(email, pin, this.state.verificationToken).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
        });
        localStorageService.setUser(response.data);
        this.props.navigation("/wallet");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          successful: false,
          message: resMessage,
        });
      }
    );
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    UserService.verifyEmail(this.state.email, this.state.verificationToken).then(
      (response) => {
        this.setState({
          isEmailVerified: true,
          failedToVerifyEmail: false,
          loading: false,
        });
      },
      (error) => {
        this.setState({
          failedToVerifyEmail: true,
          isEmailVerified: false,
          loading: false,
        });
      }
    );
  }

  render() {
    const {
      successful,
      message,
      isEmailVerified,
      email,
      failedToVerifyEmail,
      loading,
      pin,
      isPinVerified,
    } = this.state;
    const initialValues = {
      pin: "",
      email: email,
    };

    return (
      <div className="col-md-12">
        <div className="our-card card-container" style={{
          backgroundImage: `url(${cloudsImageSrc})`,
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat"
        }}>
          <h2 className="page-title">
            <p style={{ display: "inline", marginRight: "14px" }}>Amplify Rewards</p>
          </h2>

          <div style={{ textAlign: "center", marginBottom: "10px" }}>
            <img
              src={emailImageSrc}
              alt="Email"
            />
          </div>

          {loading && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Spinner
                animation="border"
                variant="light"
                className="large-spinner"
              ></Spinner>
            </div>
          )}

          {failedToVerifyEmail && (
            <div className="alert alert-danger">
              Failed to verify your email address. Contact the administrator or
              try again later.
            </div>
          )}

          {isEmailVerified && !this.state.pin && (
            <div>
              <p className="page-action" style={{ paddingLeft: "20px", paddingRight: "20px" }}> Your email has been verified!</p>
              <div style={{ backgroundColor: "white" }}>
                <p className="page-description">
                  To protect your account, please setup a PIN.
                </p>

                <Formik
                  initialValues={initialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={this.handleSetPin}
                >
                  <Form>
                    {!successful && (
                      <div style={{ marginLeft: "20px", marginRight: "20px", paddingBottom: "20px" }}>
                        <div className="form-group">
                          <Field
                            name="pin"
                            type="password"
                            placeholder="Your pin"
                            className="form-control input-field "
                            inputMode="numeric"
                          />
                          <ErrorMessage
                            name="pin"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div className="form-group" style={{ marginTop: "20px" }}>
                          <button type="submit" className="full-width-button">
                            <span>Set PIN</span>
                          </button>
                        </div>
                      </div>
                    )}
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                  </Form>
                </Formik>
              </div>
            </div>
          )}

          {isEmailVerified && pin && !isPinVerified && (
            <div>
              <p className="page-action" style={{ paddingLeft: "20px", paddingRight: "20px" }}>PIN verification</p>
              <div style={{ backgroundColor: "white" }}>
                <p className="page-description">
                  Verify your PIN to finish registration.
                </p>
                <Formik
                  initialValues={initialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={this.handleSetVerifiedPin}
                >
                  <Form>
                    {!successful && (
                      <div style={{ marginLeft: "20px", marginRight: "20px", paddingBottom: "20px" }}>
                        <div className="form-group">
                          <Field
                            name="pin"
                            type="password"
                            placeholder="Your pin"
                            className="form-control input-field "
                            inputMode="numeric"
                          />
                          <ErrorMessage
                            name="pin"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div className="form-group" style={{ marginTop: "20px" }}>
                          <button type="submit" className="full-width-button">
                            <span>Confirm PIN</span>
                          </button>
                        </div>
                      </div>
                    )}
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                  </Form>
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
