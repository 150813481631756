import { IContract } from "../../interfaces/IContract";
import React, { useState } from "react";
import axios from "axios";
import authHeader from "../../services/Auth.header";
import { Switch } from "@mui/material";
import envconfig from "../../environment-config";
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";
import { ICategory } from "../../interfaces/ICategory";
import { IEvent } from "../../interfaces/IEvent";

export function NFTcontract(input: any) {
  const [nftContract, setNftContract]: [IContract, (nftContract: IContract) => void] =
    React.useState(input.post);

  const [isActive, setIsActive]: [boolean, (isActive: boolean) => void] =
    React.useState(input.post.isActive);

  const setContractAvailability = (isChecked: boolean, contract: IContract) => {
    setIsActive(isChecked);

    axios
      .post(
        `${envconfig.API_BASE_URL}/contracts/availability`,
        {
          id: contract.id,
          isActive: isChecked,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        contract.isActive = isChecked;
      })
      .catch((ex) => { });
  };

  const categoryName = (input.categories?.find((x: ICategory) => { return input.post.categoryId === x.id }))?.name ?? "";
  const eventName = (input.events?.find((x: IEvent) => { return input.post.eventInfoId === x.id }))?.name ?? "";

  return (
    <div className="post" key={nftContract.id}>
      <div style={{ height: "50px" }}>
        <span style={{ float: "left", backgroundColor: "#56D1F4", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px" }}>
          {eventName}
        </span>
        <span style={{ float: "left", backgroundColor: "#F5B815", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px" }}>
          {categoryName}
        </span>
        <span
          style={{
            float: "right",
          }}
        >
          <Switch
            checked={isActive}
            onChange={(event) =>
              setContractAvailability(event.target.checked, nftContract)
            }
          />
        </span>
      </div>

      <div>
        <div
          style={{
            display: "inline-block",
          }}
        >
          <img
            className="thumb-image"
            src={`data:image/png;base64,${nftContract.image}`}
            alt="Cool nft."
          />
        </div>

        <div
          style={{
            display: "inline-block",
          }}
        >
          <QRCode
            style={{
              marginLeft: 20,
            }}
            id="qr-gen"
            value={`${envconfig.FRONTEND_URL}/mint/${nftContract.id}`}
            size={150}
            level={"H"}
            includeMargin={true}
          />
        </div>

        <Typography id="modal-modal-title" variant="h6" component="h2">
          <div className="center nft-detail-title">{nftContract.name}</div>
        </Typography>

        <Typography id="modal-modal-description">
          <span
            style={{
              fontSize: "0.9rem",
              overflowWrap: "break-word",
            }}
          >
            {input.post.description}
          </span>
          <p
            style={{
              fontSize: "0.9rem",
              marginTop: "1em",
              wordBreak: "break-all",
              overflowWrap: "break-word",
            }}
          >
            <span className="nft-id-label">NFT&#160;ID:</span>
            &#160;
            {nftContract.address}
          </p>
        </Typography>

        {/* <div className="nft-address">{post.address}</div> */}
        <a
          href={`${envconfig.FRONTEND_URL}/mint/${nftContract.id}`}
          style={{
            display: "block",
            textAlign: "right",
            marginRight: "9px",
          }}
        >
          Mint
        </a>
      </div>
    </div>
  );
}
