import React from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from "react-router-dom";
import UserService from "../../services/User.service";
import { SvgIcon } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrScanner from "qr-scanner";
import ModalBootstrap from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/esm/Spinner";
import Profile from "../userManagement/Profile";

function Navbar() {
    const navigate = useNavigate();

    let user = UserService.getCurrentUser();

    const [openedQrScanner, setOpenedQrScanner] = React.useState(false);
    const [scanner, setScanner] = React.useState<QrScanner | null>(null);
    const [videoStarted, setVideoStarted] = React.useState(false);
    const [openedProfile, setOpenedProfile] = React.useState(false);

    const renderProfileIcon = () => {
        if (UserService.getCurrentUser()) {
            return (
                <div
                    className="nav-bar"
                    style={{ zIndex: "6", position: "absolute", right: "0" }}
                >
                    <div className="icon-container">
                        <SvgIcon
                            onClick={openScanQrCode}
                            className="profile-icon"
                            fontSize="large"
                            component={QrCodeScannerIcon}
                            style={{ height: "0.86em" }}
                        />

                        <SvgIcon
                            onClick={() => setOpenedProfile(true)}
                            className="profile-icon"
                            fontSize="large"
                            component={AccountCircleOutlinedIcon}
                        />
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const openScanQrCode = () => {
        setOpenedQrScanner(true);
    };

    const scanQrCode = async () => {
        let result = document.querySelector("video");
        if (!result) return;
        let videoElement: HTMLVideoElement = result;

        if (!scanner) {
            const qrScanner = new QrScanner(
                videoElement,
                (result) => {
                    cleanUpScanner();
                    setOpenedQrScanner(false);
                    setVideoStarted(false);
                    window.location.href = result.data;
                },
                {
                    /* your options or returnDetailedScanResult: true if you're not specifying any other options */
                    highlightScanRegion: true,
                    highlightCodeOutline: true,
                }
            );
            setScanner(qrScanner);
            await qrScanner.start();
        } else {
            // await scanner?.start();
            // setVideoStarted(true);
        }
        setVideoStarted(true);
    };

    const handleCloseQrScanner = () => {
        cleanUpScanner();
        setOpenedQrScanner(false);
        setVideoStarted(false);
    };

    function cleanUpScanner() {
        if (scanner) {
            scanner.destroy();
            setScanner(null);

            let result = document.querySelector("video");
            if (!result) return;
            let videoElement: HTMLVideoElement = result;
            videoElement.pause();
        }
    }


    return (
        <div>
            {renderProfileIcon()}

            {openedQrScanner && (
                <ModalBootstrap
                    show={openedQrScanner}
                    onHide={handleCloseQrScanner}
                    onShow={scanQrCode}
                >
                    <ModalBootstrap.Header closeButton>
                        <ModalBootstrap.Title
                            style={{ color: "#06409e", fontSize: "1.2rem" }}
                        >
                            Badge Scanner
                        </ModalBootstrap.Title>
                    </ModalBootstrap.Header>
                    <ModalBootstrap.Body>
                        <div id="video-container">
                            <video id="qr-video" style={{ width: "100%" }} webkit-playsinline playsInline></video>
                            <div className="centered-container">
                                {!videoStarted && (
                                    <Spinner
                                        animation="border"
                                        variant="light"
                                        className="large-spinner"
                                    ></Spinner>
                                )}
                            </div>
                        </div>
                    </ModalBootstrap.Body>
                    <ModalBootstrap.Footer></ModalBootstrap.Footer>
                </ModalBootstrap>
            )}

            <ModalBootstrap
                show={openedProfile}
                onHide={() => setOpenedProfile(false)}>
                <ModalBootstrap.Header closeButton style={{ backgroundColor: "#d8e2f0" }}>
                    {/* <span style={{ color: "#06409e", fontSize: "1.3em", fontWeight: "600"}}>Profile</span> */}
                </ModalBootstrap.Header>
                <ModalBootstrap.Body style={{ backgroundColor: "#d8e2f0" }}>
                    <Profile />
                </ModalBootstrap.Body>
                <ModalBootstrap.Footer style={{ backgroundColor: "#d8e2f0" }}>
                </ModalBootstrap.Footer>
            </ModalBootstrap>
        </div>
    );
}

export default Navbar;