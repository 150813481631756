import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/User.service";
import Spinner from "react-bootstrap/esm/Spinner";

export default function ForgottenPin() {
  const useParamsFnc = useParams();
  const navigation = useNavigate();

  return (
    <ForgottenPinComponent
      useParamsFnc={useParamsFnc}
      navigation={navigation}
    />
  );
}

type Props = {
  useParamsFnc: any;
  navigation: NavigateFunction;
};

type State = {
  loading: boolean;
  email: string;
  successful: boolean;
  message: string;
  verificationToken: string;
};

class ForgottenPinComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSendResetPinEmail = this.handleSendResetPinEmail.bind(this);
    this.handlePinReset = this.handlePinReset.bind(this);

    let { email, token } = props.useParamsFnc;

    this.state = {
      loading: false,
      email: email,
      verificationToken: token,
      successful: false,
      message: "",
    };
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string().required("This field is required!"),
    });
  }

  handleSendResetPinEmail(formValue: { email: string }) {
    const { email } = formValue;
    this.setState({
      message: "",
      successful: false,
      loading: true,
    });

    UserService.sendForgottenPinEmail(email).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
          loading: false,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          successful: false,
          message: resMessage,
          loading: false,
        });
      }
    );
  }

  handlePinReset(formValue: {
    email: string;
    verificationToken: string;
    newPin: string;
  }) {
    const { email, verificationToken, newPin } = formValue;
    this.setState({
      message: "",
      successful: false,
      loading: true,
    });

    UserService.resetForgottenPin(email, verificationToken, newPin).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
          loading: false,
        });
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          successful: false,
          message: resMessage,
          loading: false,
        });
      }
    );
  }

  validationResetSchema() {
    return Yup.object().shape({
      newPin: Yup.string().required("This field is required!"),
    });
  }

  render() {
    const { successful, message, email, loading, verificationToken } =
      this.state;
    const initialValues = {
      email: "",
    };

    const initialValuesResetPin = {
      email: email,
      verificationToken: verificationToken,
      newPin: "",
    };

    return (
      <div className="col-md-12">
        <div className="our-card card-container" style={{ padding: "20px" }}>
          <div className="pdi-login-header-container">
            <p className="page-action">Amplify Rewards</p>
          </div>

          {!verificationToken && (
            <div>
              <p className="page-description">
                In order to reset your PIN, please enter your email. We will
                send a reset link there.
              </p>

              <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleSendResetPinEmail}
              >
                <Form>
                  {!successful && (
                    <div>
                      <div className="form-group">
                        <Field
                          id="email"
                          style={{
                            marginBottom: "10px",
                            backgroundColor: "white !important",
                          }}
                          name="email"
                          type="text"
                          className="form-control input-field"
                          placeholder="E-mail"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>

                      <button
                        style={{
                          marginTop: "40px",
                        }}
                        type="submit"
                        className="full-width-button"
                        disabled={loading}
                      >
                        <span>
                          {loading && (
                            <Spinner
                              animation="border"
                              variant="light"
                            ></Spinner>
                          )}
                          Send email
                        </span>
                      </button>
                    </div>
                  )}
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          )}

          {verificationToken && (
            <div>
              <p className="page-description">You can reset your PIN here.</p>

              <Formik
                initialValues={initialValuesResetPin}
                validationSchema={this.validationResetSchema}
                onSubmit={this.handlePinReset}
              >
                <Form>
                  {!successful && (
                    <div>
                      <div className="form-group">
                        <Field
                          style={{
                            marginBottom: "10px",
                            backgroundColor: "white !important",
                          }}
                          name="newPin"
                          type="password"
                          placeholder="New PIN"
                          className="form-control input-field "
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="newPin"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>
                      <div className="form-group" style={{ marginTop: "20px" }}>
                        <button type="submit" className="full-width-button">
                          <span>Reset</span>
                        </button>
                      </div>
                    </div>
                  )}
                  {message && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {message}
                      </div>
                    </div>
                  )}
                </Form>
              </Formik>
            </div>
          )}
        </div>
      </div>
    );
  }
}
