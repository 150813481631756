import localStorageService from "../../services/LocalStorage.service";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SvgIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

function TemporaryWallet() {
    const navigation = useNavigate();

    const nfts = localStorageService.getNftsToMint();
    const claimNFT = () => {
        navigation("/register");
    };

    return (
        <div>
            <div className="alert">
                <div className="pdi-page-notification__body">
                    <div className="pdi-page-notification__body__accent-bar"> </div>
                    <span className="pdi-page-notification__body__content">
                        <SvgIcon className="pdi-page-notification__body__content-icon pdi-icon-alert"
                            fontSize="medium"
                            component={ErrorOutlineIcon} />
                        <span className="pdi-body-small pdi-page-notification__body__content-text">
                            <strong>Badges are stored solely in your browser</strong> and can be deleted.
                            If you want to store them permanently, click the <strong>Save</strong> button.
                        </span>
                    </span>
                </div>
            </div>

            <div style={{ margin: "20px" }}>
                <p className="page-action">Temporary AMPLIFY Rewards</p>

                <div className="nft-list-container">
                    {nfts.map((nft) => (
                        <div className="nft-container">
                            {nft.image && (
                                <img
                                    className="thumb-image"
                                    src={`data:image/png;base64,${nft.image}`}
                                    alt=""
                                // onClick={(e) => handleOpen(e, nft)}
                                />
                            )}
                        </div>
                    ))}
                </div>

                <button
                    style={{ marginTop: "30px" }}
                    className="full-width-button"
                    onClick={claimNFT}
                >
                    <span> Save </span>
                </button>
            </div>
        </div>
    );
}

export default TemporaryWallet;
