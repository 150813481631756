import axios from "axios";
import envconfig from "../environment-config";
import authHeader from "./Auth.header";
import { ICategory } from "../interfaces/ICategory";

const API_URL = `${envconfig.API_BASE_URL}/categories`;

class CategoryService {
    async list() {
        return axios.get<ICategory[]>(
            `${API_URL}`,
            {
                headers: authHeader(),
            }
        )
    }

    async add(category: any) {
        return axios
            .post(
                `${API_URL}`,
                {
                    name: category.name
                },
                {
                    headers: authHeader(),
                }
            )
    }

    async delete(id: string) {
        return axios
            .delete(
                `${API_URL}?id=${id}`,
                {
                    headers: authHeader(),
                }
            )
    }
}

export default new CategoryService();