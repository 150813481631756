import axios from "axios";
import { IUser } from "../interfaces/IUser";
import envconfig from "../environment-config";
import authHeader from "./Auth.header";
import { IContract } from "../interfaces/IContract";
import localStorageService from "./LocalStorage.service";
import { INft } from "../interfaces/INft";

const API_URL = `${envconfig.API_BASE_URL}/users/`;

class UserService {
  async login(email: string, pin: string, nftsToMint: IContract[]) {
    const response = await axios
      .post(API_URL + "signin", {
        email,
        pin,
        nftsToMint: nftsToMint.map((x) => x.id),
      });
    if (response.data) {
      localStorage.setItem("user", JSON.stringify(response.data));
      let parsedToken = this.parseJwt(response.data.accessToken);
      localStorage.setItem("tokenExpiresAt", parsedToken.exp);
    }
    localStorageService.clearNftsToMint();
    return response.data;
  }

  async refreshAccessToken(email: string, refreshToken: string) {
    return axios
      .post(API_URL + "get-access-token", {
        email,
        refreshToken,
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
          let parsedToken = this.parseJwt(response.data.accessToken);
          localStorage.setItem("tokenExpiresAt", parsedToken.exp);
          console.log("Token renewed");
          console.log(response.data);
        }

        localStorageService.clearNftsToMint();
        return response.data;
      });
  }

  async loginWithExternalId(externalId: string, nftsToMint: IContract[]) {
    return axios
      .post(API_URL + "signinWithId", {
        externalId,
        nftsToMint: nftsToMint.map((x) => x.id),
      })
      .then((response) => {
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
          let parsedToken = this.parseJwt(response.data.accessToken);
          localStorage.setItem("tokenExpiresAt", parsedToken.exp);
        }

        localStorageService.clearNftsToMint();
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpiresAt");
  }

  register(email: string, nftsToMint: string[]) {
    return axios.post(API_URL + "signup", {
      email,
      isAdmin: false,
      nftsToMint: nftsToMint
    });
  }

  setPin(email: string, pin: string, verificationToken: string) {
    return axios.post(API_URL + "set-pin", {
      email,
      pin,
      verificationToken,
    });
  }

  resetPin(email: string, currentPin: string, newPin: string) {
    return axios.post(API_URL + "reset-pin", {
      email,
      currentPin,
      newPin,
    });
  }

  sendForgottenPinEmail(email: string) {
    return axios.post(API_URL + "forgotten-pin", {
      email,
    });
  }

  resetForgottenPin(
    email: string,
    verificationToken: string,
    newPin: string
  ) {
    return axios.post(API_URL + "reset-forgotten-pin", {
      email,
      verificationToken,
      newPin,
    });
  }

  verifyEmail(email: string, token: string) {
    return axios.post(
      API_URL + "verify-email",
      {
        email,
        token,
      },
      { headers: authHeader() }
    );
  }

  getCurrentUser(): IUser | null {
    const userStr = localStorage.getItem("user");

    if (userStr) {
      let user: IUser = JSON.parse(userStr);
      return user;
    }

    return null;
  }

  startTokenExpirationCheck() {
    setInterval(() => {
      var tokenExpiresAt = localStorage.getItem("tokenExpiresAt");
      console.log("Checking token expiration.");

      if (tokenExpiresAt && Number(tokenExpiresAt) < Date.now() / 1000) {
        console.log("Token expired");
        const userStr = localStorage.getItem("user");
        let user: IUser;
        if (userStr) {
          user = JSON.parse(userStr);

          this.refreshAccessToken(user.email, user.refreshToken)
            .then((response) => {
            })
            .catch((error) => {
              this.logout();
              // window.location.reload();
            });
        } else {
          this.logout();
          // window.location.reload();
        }
      }
    }, 1000);
  }

  async getPrivateKey(email: string, pin: string) {
    return axios
      .post(API_URL + "get-private-key", {
        email,
        pin,
      })
      .then((response) => {
        return response.data;
      });
  }

  parseJwt(token: string) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  mintAllNfts(user: IUser) {
    return axios
      .post(
        `${envconfig.API_BASE_URL}/nfts/mintAllUserNfts`,
        {
          wallet: user?.wallet ?? ""
        },
        {
          headers: authHeader(),
        }
      )
  }

  getWalletBalance(user: IUser) {
    return axios.get<INft[]>(
      `${envconfig.API_BASE_URL}/wallet/balance?wallet=${user.wallet}`,
      {
        headers: authHeader(),
      }
    )
  }
}

export default new UserService();
