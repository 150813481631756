import React from "react";
import axios from "axios";
import authHeader from "../../services/Auth.header";
import envconfig from "../../environment-config";
import { useNavigate } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";
import Spinner from "react-bootstrap/esm/Spinner";

function NftsImport() {
    const navigation = useNavigate();
    const [loading, setLoading]: [boolean, (loading: boolean) => void] =
        React.useState(false);
    const [error, setError]: [string, (error: string) => void] =
        React.useState("");
    const [message, setMessage]: [string, (error: string) => void] =
        React.useState("");
    const [selectedFile, setSelectedFile] = React.useState<File>();

    const changeHandler = (event: any) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmission = () => {
        const formData: any = new FormData();
        formData.append("csv", selectedFile);
        setLoading(true);

        return axios
            .post(`${envconfig.API_BASE_URL}/contracts/import`, formData, {
                headers: authHeader(),
            })
            .then((response) => {
                setError("");
                setMessage("Nfts imported successfully.");
                setLoading(false);
            })
            .catch((ex) => {
                if (ex.response.status === 401) {
                    localStorageService.clearCache();
                    navigation(`/login/?url=${window.location.pathname}`);
                }

                const error =
                    ex.response.status === 404
                        ? "Resource Not found"
                        : "An unexpected error has occurred";
                setError(error);
                setMessage("");
                setLoading(false);
            });
    };

    return (
        <div style={{ margin: "10px" }}>
            <div
                style={{
                    width: "100%",
                    padding: "20px",
                    backgroundColor: "white",
                    borderRadius: "10px",
                }}
            >
                <div>
                    <h2 style={{ color: "#06409e", fontSize: "1.2rem" }}>NFT import</h2>
                    <div>
                        <div>Imports nfts from csv.</div>
                        <div>
                            Format: id, symbol, name, description, imageUrl, points, event, category.
                        </div>
                        <div style={{ marginTop: "20px" }}>
                            <input type="file" name="file" onChange={changeHandler} />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "30px" }}>
                    <button
                        style={{ marginLeft: "auto" }}
                        className="primary-button"
                        onClick={handleSubmission}
                    >
                        <span>
                            {loading && (
                                <Spinner animation="border" variant="light"></Spinner>
                            )}
                            Import
                        </span>{" "}
                    </button>
                </div>
                {error && <p className="error">{error}</p>}
                {message && <p>{message}</p>}
            </div>
        </div>
    );
}

export default NftsImport;
