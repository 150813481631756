import React from "react";
import { IEvent } from "../../interfaces/IEvent";
import EventsService from "../../services/Events.service";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Modal, SvgIcon } from "@mui/material";
import Spinner from "react-bootstrap/esm/Spinner";
import Typography from "@mui/material/Typography";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    useNavigate,
} from "react-router-dom";

function EventsList() {
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const navigation = useNavigate();
    const defaultEvents: IEvent[] = [];
    const [events, setEvents]: [IEvent[], (events: IEvent[]) => void] = React.useState(defaultEvents);
    const userStr = localStorage.getItem("user");
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [showSubmitFormSpinner, setShowSpinner] = React.useState<boolean>(false);
    const handleAddEvent = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const initialValues = {
        name: "",
        location: "",
        startDate: new Date(),
        endDate: new Date(),
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
        minWidth: "200px",
        maxWidth: "400px",
        width: "100%;",
    };

    let user: any = null;
    if (userStr) {
        user = JSON.parse(userStr);
    }

    React.useEffect(() => {
        setLoading(true);

        EventsService
            .getEventsWithUserStats(user)
            .then(x => {
                setEvents(x.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error.response.status === 401) {
                    navigation(`/login/?url=${window.location.pathname}`);
                }
            });
    }, []);

    function handleOpenEventDetail(id: string) {
        navigation(`event/${id}`);
    }

    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required("This field is required!"),
            location: Yup.string().required("This field is required!"),
        });
    };

    const handleSubmit = (formValue: {
        name: string;
        location: string;
        startDate: Date;
        endDate: Date;
    }) => {
        const { name, location } = formValue;

        const formData: any = new FormData();
        formData.append("name", name);
        formData.append("location", location);
        formData.append("startDate", startDate);
        formData.append("endDate", endDate);

        setShowSpinner(true);

        EventsService.addEvent({ name: name, location: location, startDate: startDate, endDate: endDate }).then(x => {
            events.push(x.data);
            setEvents(events);
            setOpen(false);
        });
    };

    const handleDeleteEvent = (id: string): any => {
        EventsService.deleteEvent(id).then(x => {
            let filtered = events.filter(x => x.id !== id)
            console.log(filtered);
            setEvents(filtered);
        });
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <div style={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
                <h2 style={{ display: "inline", fontWeight: "600", verticalAlign: "middle" }}>Events</h2>
                {user.isAdmin &&
                    <div className="add-contract-button-container" style={{ display: "inline-block" }}>
                        <SvgIcon
                            fontSize="large"
                            className="profile-icon"
                            component={AddCircleOutlineIcon}
                            onClick={handleAddEvent}
                        />
                    </div>
                }
                <span style={{ display: "inline", float: "right", color: "gray", fontSize: "0.8rem" }} >{events.length} Events Total</span>
            </div>

            {loading && (
                <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                    <Spinner animation="border" variant="primary" className="large-spinner"></Spinner>
                </div>
            )}

            {!loading && events && events.map((event) => (
                <div style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid lightgrey",
                    backgroundColor: "white",
                    width: "100%",
                    display: "flex"
                }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ float: "left", width:"80%" }}>
                            <div style={{ fontWeight: "bold" }} >
                                {event.name}

                                {user.isAdmin &&
                                    <div style={{ display: "inline-block" }}>
                                        <SvgIcon
                                            className="profile-icon"
                                            fontSize="small"
                                            component={DeleteOutlineIcon}
                                            onClick={() => handleDeleteEvent(event.id)}
                                        />
                                    </div>
                                }
                            </div>
                            <div style={{ fontSize: "0.95rem" }}>
                                {event.location}
                            </div>
                            <div style={{ fontSize: "0.95rem" }}>
                                {EventsService.formatDate(event.startDate, event.endDate)}
                            </div>
                            <div>
                                <span style={{ backgroundColor: "#56D1F4", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px" }}>
                                    {event.acquiredNfts}&nbsp;/&nbsp;{event.nftsCount} Badges
                                </span>
                                <span style={{ backgroundColor: "#F5B815", borderRadius: "5px", padding: "4px", paddingLeft: "10px", paddingRight: "10px", fontSize: "0.85rem", fontWeight: "bold", marginRight: "5px" }}>
                                    {event.acquiredPoints}&nbsp;/&nbsp;{event.totalPoints} Points
                                </span>
                            </div>
                        </div>

                        <div style={{ float: "right", height: "100%" }} onClick={() => handleOpenEventDetail(event.id)}>
                            <SvgIcon style={{ color: "lightgray", height: "100%", fontSize: "1.5rem" }}
                                className="profile-icon"
                                component={ArrowForwardIosIcon}
                            />
                        </div>
                    </div>
                </div>
            ))}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h2 className="page-title">Add Event</h2>
                    </Typography>
                    <div style={{ marginTop: 2 }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="form-control input-field"
                                        placeholder="Name of the event."
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <Field
                                        name="location"
                                        type="text"
                                        className="form-control input-field"
                                        placeholder="Location of event."
                                    />
                                    <ErrorMessage
                                        name="location"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="startDate">Start date</label>
                                    <DatePicker
                                        className="form-control input-field"
                                        selected={startDate}
                                        onChange={(startDate) => setStartDate(startDate ?? new Date())}
                                    />
                                    <ErrorMessage
                                        name="startDate"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="endDate">End date</label>
                                    <DatePicker
                                        className="form-control input-field"
                                        selected={endDate}
                                        onChange={(endDate) => {
                                            setEndDate(endDate ?? new Date())
                                        }}
                                    />
                                    <ErrorMessage
                                        name="endDate"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="full-width-button"
                                        style={{ marginTop: "2em" }}
                                    >
                                        <span>
                                            {showSubmitFormSpinner && (
                                                <Spinner animation="border" variant="light"></Spinner>
                                            )}
                                            SAVE
                                        </span>
                                    </button>
                                </div>

                            </Form>
                        </Formik>
                    </div>
                </Box>
            </Modal>

            {/* <ConfirmationDialog title="" text="" handleOk={() => console.log("OK")}></ConfirmationDialog> */}
        </div>
    );
}

export default EventsList;