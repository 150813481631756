import React from "react";
import EventsService from "../../services/Events.service";
import { ILeaderboard } from "../../interfaces/ILeaderboard";
import Spinner from "react-bootstrap/esm/Spinner";
import { Grid } from "@mui/material";


interface Properties {
    eventId: string;
}

function Leaderboard({ eventId }: Properties): JSX.Element {
    const [leaderboard, setLeaderboard]: [ILeaderboard, (board: ILeaderboard) => void] = React.useState({} as ILeaderboard);
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);

    React.useEffect(() => {
        EventsService
            .getLeaderboard(eventId)
            .then(x => {
                setLeaderboard(x.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
            });
    }, []);

    return <div style={{ backgroundColor: "white" }}>
        <div style={{ borderBottom: "1px solid lightgrey", padding: "10px" }}>
            <h4 style={{ display: "inline", fontWeight: "600", verticalAlign: "middle" }}>Leaderboard</h4>
            <span style={{ display: "inline", float: "right", color: "gray", fontSize: "0.8rem" }} >Top 100</span>
        </div>

        <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} style={{ backgroundColor: "#D8E2F0", fontWeight: "600", fontSize: "0.9rem", paddingLeft: "10px", paddingRight: "10px" }}>
            <Grid item xs={1}>
                <span style={{ marginLeft: "3px" }}>#</span>
            </Grid>
            <Grid item xs={7}>
                <span>NAME</span>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
                <span>BADGES</span>
            </Grid>
            <Grid item xs={2} style={{ textAlign: "right" }}>
                <span>PTS</span>
            </Grid>
        </Grid>
        {loading && (
            <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                <Spinner animation="border" variant="primary" className="large-spinner"></Spinner>
            </div>
        )}
        {!loading && leaderboard.contestants && leaderboard.contestants.map((contestant) => (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} style={{ fontSize: "0.9rem", paddingLeft: "10px", paddingRight: "10px", borderBottom: "1px solid rgb(216, 226, 240)", marginTop: "0px" }}>
                <Grid item xs={1}>
                    <span style={{
                        fontWeight: "bold",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        paddingTop: "2px",
                        paddingBottom: "2px",
                        borderRadius: "3px",
                        backgroundColor:
                         contestant.place === 1 ? "#F5B815" :
                         contestant.place === 2 ? "#D6D8D6" :
                         contestant.place === 3 ? "#D7B58C" : "rgb(216, 226, 240)",
                    }}>
                        
                        {contestant.place}
                    </span>
                </Grid>
                <Grid item xs={7} style={{ fontWeight: "bold" }}>
                    {contestant.firstName}&nbsp;{contestant.lastName}
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                    {contestant.badges}
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                    {contestant.points}
                </Grid>
            </Grid>
        ))}

    </div>
}

export default Leaderboard;