import { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../services/User.service";
import Spinner from "react-bootstrap/Spinner";
import tokensIcon from "../../assets/tokens.png";
import { useParams, useNavigate, NavigateFunction } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";

export default function RegisterEmail() {
  const useParamsFnc = useParams(); // extract navigation prop here
  const navigation = useNavigate(); // extract navigation prop here

  return (
    <RegisterEmailComponent useParamsFnc={useParamsFnc} navigation={navigation} />
  ); //pass to your component.
}

type Props = {
  useParamsFnc: any;
  navigation: NavigateFunction;
};

type State = {
  email: string;
  successful: boolean;
  message: string;
  loading: boolean;
  hasPin: boolean;
};

class RegisterEmailComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    this.state = {
      email: "",
      successful: false,
      message: "",
      loading: false,
      hasPin: false,
    };
  }

  validationSchemaRegister() {
    return Yup.object().shape({
      email: Yup.string()
        .email("This is not a valid email.")
        .required("This field is required!"),
    });
  }

  validationSchemaLogin() {
    return Yup.object().shape({
      pin: Yup.string()
        .required("This field is required!")
        .matches(/^\d+$/, "PIN must contain only digits."),
    });
  }

  handleRegister(formValue: { email: string }) {
    const { email } = formValue;
    this.setState({
      message: "",
      successful: false,
      loading: true,
      hasPin: false,
      email: email,
    });

    var nftsToMint = localStorageService.getNftsToMint().map(x=> x.id);

    UserService.register(email, nftsToMint).then(
      (response) => {
        this.setState({
          message: response.data.message,
          successful: true,
          loading: false,
        });

        localStorageService.clearNftsToMint();
      },
      (error) => {
        if (error.response.status === 302) {
          this.setState({
            successful: false,
            message: "",
            loading: false,
            hasPin: error.response.data.hasPin,
          });
        } else {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            successful: false,
            message: resMessage,
            loading: false,
            hasPin: false,
          });
        }
      }
    );
  }

  handleLogin(formValue: { email: string; pin: string }) {
    const { email, pin } = formValue;
    this.setState({
      message: "",
      successful: false,
      loading: true,
    });

    var nftsToMint = localStorageService.getNftsToMint();

    UserService.login(email, pin, nftsToMint).then(
      () => {
        this.props.navigation("/wallet");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        if (error.response.status === 404) {
          this.setState({
            loading: false,
            message: resMessage,
          });
        } else {
          this.setState({
            loading: false,
            message: resMessage,
          });
        }
      }
    );
  }

  render() {
    const { successful, message, loading, hasPin } = this.state;
    const initialValuesRegister = {
      email: "",
    };
    const initialValuesLogin = {
      email: this.state.email,
      pin: "",
    };

    return (
      <div className="col-md-12 h100">
        <div className="our-card card-container">
          <p
            className="page-action"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            Save your badge to your wallet
          </p>

          <div
            className="pdi-form-body-container"
            style={{ paddingLeft: "20px", paddingRight: "20px", zIndex: "5" }}
          >
            {!hasPin && (
              <div>
                <p className="page-description">
                  In order to save your badge, please enter your email. We will
                  send a verification link there.
                </p>

                <Formik
                  initialValues={initialValuesRegister}
                  validationSchema={this.validationSchemaRegister}
                  onSubmit={this.handleRegister}
                >
                  <Form>
                    {!successful && (
                      <div style={{ textAlign: "center" }}>
                        <div className="form-group">
                          <Field
                            name="email"
                            type="text"
                            placeholder="Your e-mail"
                            className="form-control input-field"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div
                          className="form-group"
                          style={{ marginTop: "20px" }}
                        >
                          <button
                            type="submit"
                            className="full-width-button"
                            disabled={loading}
                          >
                            <span>
                              {loading && (
                                <Spinner
                                  animation="border"
                                  variant="light"
                                ></Spinner>
                              )}
                              Next
                            </span>
                          </button>
                        </div>
                        <div className="horizontal-separator">
                          <span>or</span>
                        </div>
                        <a href="/login">I already have an account</a>
                      </div>
                    )}
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                  </Form>
                </Formik>
              </div>
            )}

            {hasPin && (
              <div>
                <p className="page-description">
                  Your email is already registered. Use your pin to access your account.
                </p>
                <Formik
                  initialValues={initialValuesLogin}
                  validationSchema={this.validationSchemaLogin}
                  onSubmit={this.handleLogin}
                >
                  <Form>
                    <div style={{ textAlign: "center" }}>
                      <div className="form-group">
                        <Field
                          id="pin"
                          style={{
                            marginBottom: "10px",
                            backgroundColor: "white !important",
                          }}
                          name="pin"
                          type="password"
                          className="form-control input-field"
                          placeholder="PIN"
                          inputMode="numeric"
                        />
                        <ErrorMessage
                          name="pin"
                          component="div"
                          className="alert alert-danger"
                        />
                      </div>

                      <div className="form-group">
                        <button
                          style={{
                            marginTop: "40px",
                          }}
                          type="submit"
                          className="full-width-button"
                          disabled={loading}
                        >
                          <span>
                            {loading && (
                              <Spinner
                                animation="border"
                                variant="light"
                              ></Spinner>
                            )}
                            Next
                          </span>
                        </button>
                      </div>
                    </div>
                    {message && (
                      <div className="form-group">
                        <div
                          className={
                            successful
                              ? "alert alert-success"
                              : "alert alert-danger"
                          }
                          role="alert"
                        >
                          {message}
                        </div>
                      </div>
                    )}
                  </Form>
                </Formik>
              </div>
            )}
          </div>
        </div>
        <div className="background-img-wrapper">
          <img className="register-image" src={tokensIcon} alt="Badges." />
        </div>
      </div>
    );
  }
}
