import React from "react";
import { IEvent } from "../../interfaces/IEvent";
import CategoryService from "../../services/Category.service";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Box, Modal, SvgIcon } from "@mui/material";
import Spinner from "react-bootstrap/esm/Spinner";
import Typography from "@mui/material/Typography";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
    useNavigate,
} from "react-router-dom";
import { ICategory } from "../../interfaces/ICategory";

function CategoryList() {
    const [loading, setLoading]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);
    const navigation = useNavigate();
    const defaultItems: ICategory[] = [];
    const [categories, setCategories]: [ICategory[], (events: ICategory[]) => void] = React.useState(defaultItems);
    const [open, setOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());
    const [showSubmitFormSpinner, setShowSpinner] = React.useState<boolean>(false);
    const handleAddEvent = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const userStr = localStorage.getItem("user");

    let user: any = null;
    if (userStr) {
        user = JSON.parse(userStr);
    }

    if(!user.isAdmin)
    throw "Unauthorized";

    const initialValues = {
        name: ""
    };

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        borderRadius: "5px",
        boxShadow: 24,
        p: 4,
        minWidth: "200px",
        maxWidth: "400px",
        width: "100%;",
    };

    React.useEffect(() => {
        setLoading(true);

        CategoryService
            .list()
            .then(x => {
                setCategories(x.data);
                setLoading(false);
            }).catch(error => {
                setLoading(false);
                if (error.response.status === 401) {
                    navigation(`/login/?url=${window.location.pathname}`);
                }
            });
    }, []);

    const validationSchema = () => {
        return Yup.object().shape({
            name: Yup.string().required("This field is required!"),
        });
    };

    const handleSubmit = (formValue: {
        name: string;
    }) => {
        const { name } = formValue;

        const formData: any = new FormData();
        formData.append("name", name);
        setShowSpinner(true);

        CategoryService.add({ name: name }).then(x => {
            categories.push(x.data);
            setCategories(categories);
            setOpen(false);
        });
    };

    const handleDelete = (id: string): any => {
        CategoryService.delete(id).then(x => {
            let filtered = categories.filter(x => x.id !== id)
            setCategories(filtered);
        });
    };

    return (
        <div style={{ backgroundColor: "white" }}>
            <div style={{ borderBottom: "1px solid lightgrey", padding: "20px" }}>
                <h2 style={{ display: "inline", fontWeight: "600", verticalAlign: "middle" }}>Categories</h2>
                <div className="add-contract-button-container" style={{ display: "inline-block" }}>
                    <SvgIcon
                        fontSize="large"
                        className="profile-icon"
                        component={AddCircleOutlineIcon}
                        onClick={handleAddEvent}
                    />
                </div>
                <span style={{ display: "inline", float: "right", color: "gray", fontSize: "0.8rem" }} >{categories.length} Events Total</span>
            </div>

            {loading && (
                <div style={{ width: "100%", textAlign: "center", padding: "10px" }}>
                    <Spinner animation="border" variant="primary" className="large-spinner"></Spinner>
                </div>
            )}

            {!loading && categories && categories.map((category) => (
                <div style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    borderBottom: "1px solid lightgrey",
                    backgroundColor: "white",
                    width: "100%",
                    display: "flex"
                }}>
                    <div style={{ width: "100%" }}>
                        <div style={{ float: "left" }}>
                            <div style={{ fontWeight: "bold" }} >
                                <div style={{ display: "inline-block" }}>
                                    <SvgIcon
                                        className="profile-icon"
                                        fontSize="small"
                                        component={DeleteOutlineIcon}
                                        onClick={() => handleDelete(category.id)}
                                    />
                                </div>
                                {category.name}
                            </div>
                        </div>
                    </div>
                </div>
            ))}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <h2 className="page-title">Add Category</h2>
                    </Typography>
                    <div style={{ marginTop: 2 }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}>
                            <Form>
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <Field
                                        name="name"
                                        type="text"
                                        className="form-control input-field"
                                        placeholder="Name of the event."
                                    />
                                    <ErrorMessage
                                        name="name"
                                        component="div"
                                        className="alert alert-danger"
                                    />
                                </div>

                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="full-width-button"
                                        style={{ marginTop: "2em" }}
                                    >
                                        <span>
                                            {showSubmitFormSpinner && (
                                                <Spinner animation="border" variant="light"></Spinner>
                                            )}
                                            SAVE
                                        </span>
                                    </button>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </Box>
            </Modal>

            {/* <ConfirmationDialog title="" text="" handleOk={() => console.log("OK")}></ConfirmationDialog> */}
        </div>
    );
}

export default CategoryList;