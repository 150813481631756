import { INft } from "../../interfaces/INft";
import React from "react";
import { SvgIcon } from "@mui/material";
import Spinner from "react-bootstrap/esm/Spinner";
import { useNavigate } from "react-router-dom";
import localStorageService from "../../services/LocalStorage.service";
import PDILogoDark from "../../assets/PDILogoDark.png";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import UserService from "../../services/User.service";
import NFTdetail from "../shared/NFTdetail";

function NFTlist() {
  const navigation = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = (e: any, nft: INft) => {
    setSelectedNFT(nft);
    setOpen(true);
  };
  const [selectedNFT, setSelectedNFT] = React.useState<INft>();
  const [loading, setLoading] = React.useState<boolean>(true);

  const defaultNfts: INft[] = [];
  const [nfts, setNfts]: [INft[], (nfts: INft[]) => void] =
    React.useState(defaultNfts);
  const userStr = localStorage.getItem("user");

  let user: any = null;
  if (userStr) {
    user = JSON.parse(userStr);
  }

  React.useEffect(() => {
    setLoading(true);
    UserService.getWalletBalance(user)
      .then((response) => {
        setNfts(response.data);
        setLoading(false);
      })
      .catch((ex) => {
        if (ex.response.status === 401) {
          console.log("Timed out");
          localStorageService.clearCache();
          navigation(`/login/?url=${window.location.pathname}`);
        }

        setLoading(false);
      });
  }, [user.wallet, navigation]);

  return (
    <div style={{ padding: "20px" }}>
      {!loading && (
        <div>
          <div className="nft-list-container">
            {nfts.map((nft) => (
              <div className="nft-container">
                {nft.nftContract.image && (
                  <img
                    className="thumb-image"
                    src={`data:image/png;base64,${nft.nftContract.image}`}
                    alt=""
                    onClick={(e) => handleOpen(e, nft)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {!loading && nfts.length === 0 && (
        <div style={{ textAlign: "center" }}>
          <img style={{ marginTop: "80px", width: "70%", maxWidth: "300px" }} src={PDILogoDark} alt="NFTs." />
          <h1 style={{ marginTop: "20px" }}>Welcome to PDI Wallet!</h1>
          <p>
            To mint a first NFT, simply scan it with a QR code reader.
            <SvgIcon
              className="profile-icon"
              fontSize="large"
              component={QrCodeScannerIcon}
              style={{ height: "0.86em" }}
            />
          </p>
        </div>
      )}

      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spinner
            animation="border"
            variant="light"
            className="large-spinner"
          ></Spinner>
        </div>
      )}

      {open &&
        <NFTdetail image={selectedNFT?.nftContract.image} name={selectedNFT?.nftContract.name} description={selectedNFT?.nftContract.description} points={selectedNFT?.nftContract.points} eventName={selectedNFT?.nftContract.eventInfo.name} setOpen={setOpen}></NFTdetail>
      }
    </div>
  );
}

export default NFTlist;
