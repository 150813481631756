import React from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SvgIcon from "@mui/icons-material/ArrowBackIosNew";

function ExpandButton() {
    const [toggle, setToggle]: [boolean, (loading: boolean) => void] = React.useState<boolean>(true);

    return <div onClick={() => setToggle(!toggle)}>
        {toggle && (
            <div style={{ display: "inline-block"}}>
                <SvgIcon
                    className="profile-icon"
                    fontSize="large"
                    component={KeyboardArrowDownIcon}
                    style={{ height: "0.86em", color:"lightgrey" }} />
            </div>)
        }

        {!toggle && (
            <div style={{ display: "inline-block"}}>
                <SvgIcon
                    className="profile-icon"
                    fontSize="large"
                    component={KeyboardArrowUpIcon}
                    style={{ height: "0.86em", color:"#7A7495" }} />
            </div>)
        }
    </div>
}

export default ExpandButton;