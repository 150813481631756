import "./App.scss";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Wallet from "./components/wallet/Wallet";
import Login from "./components/userManagement/Login";
import Minter from "./components/wallet/Minter";
import RegisterEmail from "./components/userManagement/RegisterEmail";
import Help from "./components/wallet/Help";
import UserService from "./services/User.service";
import { IUser } from "./interfaces/IUser";
import CredentialsVerification from "./components/userManagement/CredentialsVerification";
import UsersManagement from "./components/imports/UsersManagement";
import Profile from "./components/userManagement/Profile";
import ForgottenPin from "./components/userManagement/ForgottenPin";
import TemporaryWallet from "./components/wallet/TemporaryWallet";
import EventDetail from "./components/events/EventDetail";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";

interface Props {
  children: JSX.Element;
}

const ProtectedRoute = ({ children }: Props): JSX.Element => {
  let user = UserService.getCurrentUser();

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

var user: IUser | null;

function App() {

  return (
    <div style={{ height: "100%" }}>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/:id" element={<Login />}></Route>
        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        ></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register/:contractId" element={<RegisterEmail />}></Route>
        <Route path="/register/" element={<RegisterEmail />}></Route>
        <Route path="/users/" element={<UsersManagement />}></Route>
        <Route path="/mint/:id" element={<Minter />}></Route>
        <Route path="/mint/:id/:userId" element={<Minter />}></Route>
        <Route path="/claim/:id" element={<Minter />}></Route>
        <Route path="/claim/:id/:userId" element={<Minter />}></Route>
        <Route path="/verify/:email/:token" element={<CredentialsVerification />}></Route>
        <Route path="/help/" element={<Help />}></Route>
        <Route path="/help/:id" element={<Help />}></Route>
        <Route path="/forgotten-pin/" element={<ForgottenPin />}></Route>
        <Route path="/forgotten-pin/:email/:token" element={<ForgottenPin />}></Route>
        <Route path="/temporary-wallet" element={<TemporaryWallet />}></Route>
        <Route path="/wallet/event/:id" element={<EventDetail />}></Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
    </div>
  );
}

export default App;
